import React, {useState} from "react";
import {useTranslation} from "react-i18next";
//local
import {Input} from "components/Input";
import {ButtonTransparent} from "../buttons/ButtonTransparent";
import ButtonDelete from "components/buttons/ButtonDelete";
import {CustomTooltip} from "components/CustomTooltip";
import {ConfirmModal} from "components/ConfirmModal";
import CustomCollapse from "components/CustomCollapse";
import {IconPlus, IconEdit} from "components/icons";
import {rfpProposalsListRowHeight} from "constants/table.consts";
import {hasPermission} from "helpers/helper";
import {LockValue} from "components/LockValue";

const ProposalsHeadProposals = ({
  isShowAdjustments,
  isShowAnswers,
  isShowChecks,
  onChangeCollapse,
  onChangeTitle,
  onClickAdd,
  adjustmentsTitles,
  checkTitles,
  questions,
  currencyCode,
  publicPageMode,
}) => {
  const {t} = useTranslation();
  const [hoveredTitleId, setHoveredTitleId] = useState(null);
  const [editedTitle, setEditedTitle] = useState(null);

  const canEditAdjustments = hasPermission(["CREATE_RFT_OFFERS_ADJUSTMENTS"]);
  const canEditChecks = hasPermission(["CREATE_RFT_OFFERS_CHECKS"]);

  const handleRemoveTemplate = (type, id, isNoConfirm) => {
    if (isNoConfirm) {
      onChangeTitle(type, undefined, id, "delete", isNoConfirm);
      setEditedTitle(null);
    } else
      ConfirmModal({
        title: t(`RFP_PROPOSALS_LIST_${type.toUpperCase()}_ROW_WANT_DELETE`),
        text: t(`RFP_PROPOSALS_LIST_${type.toUpperCase()}_ROW_CANT_RETURN`),
        type: "warning",
        onOk: () => onChangeTitle(type, undefined, id, "delete"),
        onCancel: () => setEditedTitle(null),
      });
  };

  return (
    <div className="proposals-tab_head">
      <div className="proposals-tab_head__row df">
        {t("OFFER")}
        {currencyCode && <span className="mla __normal">{currencyCode}</span>}
      </div>
      <div className="proposals-tab_head__row">{t("ADJUSTED_OFFER")}</div>
      <div className="proposals-tab_head__row">
        {t("RFP_PROPOSALS_LIST_BUDGET_COLUMN")}
      </div>
      <div className="proposals-tab_head__row">
        {t("RFP_PROPOSALS_LIST_DIFF_BUDGET_COLUMN")}
      </div>
      <div className="proposals-tab_head__row __no-divider">{t("OUR_RESPONSE")}</div>
      <CustomCollapse
        title="SUM_ADJUSTMENTS"
        addClass="proposals-tab_head__row mt-2 __no-divider fz-12"
        style={{minHeight: `${rfpProposalsListRowHeight}px`}}
        isActive={isShowAdjustments}
        onChange={() => onChangeCollapse("adjustments")}
      />

      {isShowAdjustments &&
        !!adjustmentsTitles?.length &&
        adjustmentsTitles?.map((item, i) => (
          <div
            key={i}
            className={`proposals-tab_head__row __inner${
              i === adjustmentsTitles.length - 1 ? " __no-divider" : ""
            }`}
            onMouseEnter={() => setHoveredTitleId(item.id)}
            onMouseLeave={() => setHoveredTitleId(null)}
          >
            {item.id === editedTitle?.id ? (
              <CustomTooltip text="RFP_PROPOSALS_LIST_ROW_INPUT_TOOLTIP" placement="left">
                <div className="df-row-center">
                  <Input
                    tiny
                    flatView
                    fullWidth
                    autoFocus={true}
                    customHeight="26"
                    fontSize="12"
                    placeholder={t(
                      "RFP_PROPOSALS_LIST_ADJUSTMENTS_COLUMN_INPUT_PLACEHOLDER"
                    )}
                    className="input pl-2"
                    value={editedTitle.label}
                    onChange={(e) => {
                      e.stopPropagation();
                      setEditedTitle({id: item.id, label: e.target.value});
                    }}
                    onBlur={(e) => {
                      !item.label && handleRemoveTemplate("adjustments", item.id, true);
                      e.relatedTarget?.name !== "check-button-delete" &&
                        setEditedTitle(null);
                    }}
                    onKeyPress={(e) => {
                      e.key === "Enter" &&
                        onChangeTitle("adjustments", e.target.value, item.id);
                      e.key === "Enter" && setEditedTitle(null);
                    }}
                  />
                  <ButtonDelete
                    name="check-button-delete"
                    addClass="pr-0"
                    onClick={() => handleRemoveTemplate("adjustments", item.id)}
                    activeOnHover
                  />
                </div>
              </CustomTooltip>
            ) : (
              <React.Fragment>
                <CustomTooltip text={item.label}>{item.label}</CustomTooltip>
                {!publicPageMode && item.id === hoveredTitleId && (
                  <LockValue
                    addClassWrapper="mla"
                    canPermissions={["CREATE_RFT_OFFERS_ADJUSTMENTS"]}
                    needLabel
                    needShowModalToSubscribe
                  >
                    <ButtonTransparent
                      addClass="pa-0 mla"
                      iconButton
                      noBorder
                      tiny
                      icon={<IconEdit addClass="ma-0" />}
                      onClick={() => canEditAdjustments && setEditedTitle(item)}
                    />
                  </LockValue>
                )}
              </React.Fragment>
            )}
          </div>
        ))}
      {!publicPageMode && isShowAdjustments && (
        <div className="proposals-tab_head__row __no-divider">
          <LockValue
            canPermissions={["CREATE_RFT_OFFERS_ADJUSTMENTS"]}
            needLabel
            needShowModalToSubscribe
            noLockIcon
            dimmedWhenLocked
          >
            <ButtonTransparent
              icon={<IconPlus />}
              name="RFP_PROPOSALS_LIST_ANSWERS_COLUMN_BUTTON"
              noBorder
              uppercase
              bold
              addClass="pl-0"
              small
              disabled={!!editedTitle}
              onClick={() =>
                canEditAdjustments && setEditedTitle(onClickAdd("adjustments", ""))
              }
            />
          </LockValue>
        </div>
      )}

      <div className="proposals-tab_head__row __adjustments mt-2">
        <CustomCollapse
          title="RFP_PROPOSALS_LIST_ANSWERS_COLUMN"
          addClass="proposals-tab_head__row __no-divider fz-12"
          style={{minHeight: `${rfpProposalsListRowHeight}px`}}
          isActive={isShowAnswers}
          onChange={() => onChangeCollapse("answers")}
        />
      </div>
      {!!questions?.length &&
        isShowAnswers &&
        questions.map((item, i) => (
          <div
            key={item.id}
            className={`proposals-tab_head__row __question-row${
              i === questions.length - 1 ? " __no-divider" : ""
            }`}
            s
          >
            <CustomTooltip text={item.label}>{item.label}</CustomTooltip>
          </div>
        ))}

      <div className="proposals-tab_head__row __adjustments mt-2">
        <CustomCollapse
          title="RFP_PROPOSALS_LIST_CHECKS_COLUMN"
          addClass="proposals-tab_head__row __no-divider fz-12"
          style={{minHeight: `${rfpProposalsListRowHeight}px`}}
          isActive={isShowChecks}
          onChange={() => onChangeCollapse("checks")}
        />
      </div>
      {!!checkTitles?.length &&
        isShowChecks &&
        checkTitles.map((item, i) => (
          <div
            key={item.id}
            className={`proposals-tab_head__row __question-row${
              i === checkTitles.length - 1 ? " __no-divider" : ""
            }`}
            onMouseEnter={() => setHoveredTitleId(item.id)}
            onMouseLeave={() => setHoveredTitleId(null)}
          >
            {item.id === editedTitle?.id ? (
              <CustomTooltip text="RFP_PROPOSALS_LIST_ROW_INPUT_TOOLTIP" placement="left">
                <div className="df-row-center">
                  <Input
                    tiny
                    flatView
                    fullWidth
                    autoFocus={true}
                    customHeight="26"
                    fontSize="12"
                    placeholder={t("RFP_PROPOSALS_LIST_CHECKS_COLUMN_INPUT_PLACEHOLDER")}
                    className="input pl-2"
                    value={editedTitle.label}
                    onBlur={(e) => {
                      !item.label && handleRemoveTemplate("checks", item.id, true);
                      e.relatedTarget?.name !== "check-button-delete" &&
                        setEditedTitle(null);
                    }}
                    onChange={(e) => {
                      e.stopPropagation();
                      setEditedTitle({id: item.id, label: e.target.value});
                    }}
                    onKeyPress={(e) => {
                      e.key === "Enter" &&
                        onChangeTitle("checks", e.target.value, item.id);
                      e.key === "Enter" && setEditedTitle(null);
                    }}
                  />
                  <ButtonDelete
                    name="check-button-delete"
                    addClass="pr-0"
                    onClick={() => handleRemoveTemplate("checks", item.id)}
                    activeOnHover
                  />
                </div>
              </CustomTooltip>
            ) : (
              <React.Fragment>
                <CustomTooltip text={item.label}>{item.label}</CustomTooltip>
                {!publicPageMode && item.id === hoveredTitleId && (
                  <LockValue
                    addClassWrapper="mla"
                    canPermissions={["CREATE_RFT_OFFERS_CHECKS"]}
                    needLabel
                    needShowModalToSubscribe
                    noLockIcon
                    dimmedWhenLocked
                  >
                    <ButtonTransparent
                      addClass="pa-0 mla"
                      iconButton
                      noBorder
                      tiny
                      icon={<IconEdit addClass="ma-0" />}
                      onClick={() => canEditChecks && setEditedTitle(item)}
                    />
                  </LockValue>
                )}
              </React.Fragment>
            )}
          </div>
        ))}
      {!publicPageMode && isShowChecks && (
        <div className="proposals-tab_head__row __no-divider">
          <LockValue
            canPermissions={["CREATE_RFT_OFFERS_CHECKS"]}
            needLabel
            needShowModalToSubscribe
            noLockIcon
            dimmedWhenLocked
          >
            <ButtonTransparent
              icon={<IconPlus />}
              name="RFP_PROPOSALS_LIST_CHECKS_COLUMN_BUTTON"
              noBorder
              uppercase
              bold
              addClass="pl-0"
              small
              disabled={!!editedTitle}
              onClick={() => canEditChecks && setEditedTitle(onClickAdd("checks", ""))}
            />
          </LockValue>
        </div>
      )}

      <div className="proposals-tab_head__row __notes">
        {t("RFP_PROPOSALS_LIST_NOTES_COLUMN")}
      </div>
    </div>
  );
};

export default ProposalsHeadProposals;
