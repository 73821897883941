import React, {memo} from "react";

export const IconMedalGold = memo(({height, width, addClass, ...props}) => {
  return (
    <svg
      width={width || "20"}
      height={height || "20"}
      viewBox="0 0 20 20"
      fill="none"
      className={addClass}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.655 0.300049L11.2632 2.00548L13.5667 1.57099L13.8653 3.89594L15.9843 4.89834L14.8592 6.95476L15.9843 9.01118L13.8653 10.0136L13.5667 12.3385L11.2632 11.9041L9.655 13.6095L8.04681 11.9041L5.74329 12.3385L5.44471 10.0136L3.32572 9.01118L4.45079 6.95476L3.32572 4.89834L5.44471 3.89594L5.74329 1.57099L8.04681 2.00548L9.655 0.300049Z"
        fill="url(#paint0_linear_18807_37)"
      />
      <rect x="9.15747" y="4.45496" width="1" height="5" rx="0.5" fill="black" />
      <path
        fill-rule="evenodd"
        clipRule="evenodd"
        d="M11.349 1.79469L9.6574 0.000854492L7.96584 1.79469L5.5429 1.33768L5.22884 3.78316L3 4.83753L4.1834 7.00056L3 9.16358L5.22884 10.218L5.5429 12.6634L7.96584 12.2064L9.6574 14.0003L11.349 12.2064L13.7719 12.6634L14.086 10.218L16.3148 9.16358L15.1314 7.00056L16.3148 4.83753L14.086 3.78316L13.7719 1.33768L11.349 1.79469ZM12.9151 2.51688L10.9967 2.87873L9.6574 1.45842L8.31808 2.87873L6.39967 2.51688L6.15101 4.45312L4.38629 5.28794L5.32326 7.00056L4.38629 8.71317L6.15101 9.54799L6.39967 11.4842L8.31808 11.1224L9.6574 12.5427L10.9967 11.1224L12.9151 11.4842L13.1638 9.54799L14.9285 8.71317L13.9915 7.00056L14.9285 5.28794L13.1638 4.45312L12.9151 2.51688Z"
        fill="#DDA926"
      />
      <path
        d="M5.54736 12.661L6.54736 12.3282V18.3557L9.65747 16.3516L12.7674 18.3556V12.3284L13.7674 12.6611L13.7673 20.0009L9.65747 17.3516L5.54736 20.0009V12.661Z"
        fill="#DDA926"
      />
      <linearGradient
        id="paint0_linear_18807_37"
        x1="6"
        y1="2.49991"
        x2="12.9996"
        y2="11.9998"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#E6B74D" />
        <stop offset="0.495" stop-color="#FFE881" />
        <stop offset="1" stop-color="#E6B74D" />
      </linearGradient>
    </svg>
  );
});

export default IconMedalGold;
