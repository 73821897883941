import React, {memo, useMemo} from "react";
import {useTranslation} from "react-i18next";
import {useFormikContext} from "formik";
// locale
import ButtonDelete from "components/buttons/ButtonDelete";
import SimpleField from "components/SimpleField";
import MultipleSelect from "components/selects/MultipleSelect";
import Select from "components/selects/Select";
import {LockValue} from "components/LockValue";
import {IconChevronDown} from "components/icons";
import {ExplanationBubble} from "components/ExplanationBubble";

const InviteUsersFields = memo(
  ({index, remove, projectsList, subscriptionRoles, loading}) => {
    const {t} = useTranslation();
    const {values, setFieldValue} = useFormikContext();

    const fieldNameEmail = `invites.${index}.email`;
    const fieldNameRole = `invites.${index}.SubscribeRoleId`;
    const fieldNameProjects = `invites.${index}.limitProjectIds`;

    const memberSubscriptionRole = useMemo(
      () => values.invites[index]?.SubscribeRoleId,
      [values, index]
    );
    const projectsMemberList = useMemo(
      () => values.invites[index]?.limitProjectIds,
      [values, index]
    );

    const selectSubscriptionRoles = useMemo(
      () =>
        subscriptionRoles.map((item) => ({
          value: item.name,
          label: t(item.name).toUpperCase(),
        })),
      [subscriptionRoles, t]
    );
    const isSelectSubscriptionRoles = useMemo(
      () => !!selectSubscriptionRoles?.length,
      [selectSubscriptionRoles]
    );
    const selectedRolePermissions = useMemo(
      () =>
        subscriptionRoles
          .find((item) => item.id === memberSubscriptionRole)
          ?.SubscribeRolePermissions?.map((item) => item.PermissionId) ?? [],
      [subscriptionRoles, memberSubscriptionRole]
    );
    const canShowProjectLimitations = useMemo(
      () =>
        selectedRolePermissions.includes(
          "SHOW_SETTINGS_USER_SETTINGS_MODAL_PROJECT_LIMITATIONS"
        ),
      [selectedRolePermissions]
    );

    return (
      <div className="invite-user_form__item">
        <SimpleField
          placeholder={t("WHO_DO_WE_SEND_THIS_INVITATION")}
          name={fieldNameEmail}
          small
          errorName={fieldNameEmail}
          className="input"
          disabled={loading}
        />
        <LockValue
          canPermissions={["SHOW_SETTINGS_INVITE_TAB_SUBSCRIPTION_ROLE"]}
          justHide
        >
          {isSelectSubscriptionRoles && (
            <div className="df-row-center pos-r">
              <Select
                list={selectSubscriptionRoles}
                showArrow
                shadow
                huge
                smallFont
                bold
                addClass="ml-4"
                addDropdownClass="__uppercase"
                addSelectClass="__uppercase wdth-150"
                suffixIcon={<IconChevronDown />}
                value={memberSubscriptionRole}
                onChange={(value) => setFieldValue(fieldNameRole, value)}
              />
              {index === 0 && (
                <ExplanationBubble
                  title="INVITE_MEMBER_ROLES_EXPLANATION_USER_ROLE_SUB_05_HEADER"
                  text={`INVITE_MEMBER_ROLES_EXPLANATION_${memberSubscriptionRole}`}
                  minWidth="300px"
                  pointTo="bottomLeft"
                  position="absolute"
                  bottom="50px"
                  left="160px"
                />
              )}
            </div>
          )}
        </LockValue>

        {canShowProjectLimitations && (
          <MultipleSelect
            title="INVITES_TAB_INVITE_ROW_PROJECTS_SELECT_PLACEHOLDER"
            titleInButton
            value={projectsMemberList}
            list={projectsList}
            small
            realLabels
            color="purple"
            addClass="ml-4 mr-0"
            onChange={(value) => setFieldValue(fieldNameProjects, value)}
          />
        )}

        {index !== 0 && <ButtonDelete onClick={() => remove(index)} disabled={loading} />}
      </div>
    );
  }
);

export default InviteUsersFields;
