import React from "react";
import {useTranslation} from "react-i18next";
//local
import {IconPhone, IconMessage} from "components/icons";
import {InitialsBadge} from "components/InitialsBadge";
import {getFullName} from "helpers/helper";

const SellerSideProposalDescription = ({offer}) => {
  const {t} = useTranslation();

  const UserAssignee = offer?.Proposal?.UserAssignee;
  const sellerFullName = getFullName(UserAssignee?.firstName, UserAssignee?.lastName);

  return (
    <React.Fragment>
      <div className="seller-side_contact-header">{t("SELLER_CONTACT")}</div>
      {UserAssignee ? (
        <div className="df-row-center">
          <InitialsBadge firstName={sellerFullName} addClass="mr-2" />

          <span className="mr-4">{sellerFullName}</span>
          <IconPhone height="15" width="15" />
          <span className="ml-2">{UserAssignee?.mobilePhone}</span>
        </div>
      ) : (
        <span>{t("SELLER_ASSIGNEE_NOT_FOUND")}</span>
      )}
      <div className="seller-side_message-header">
        <IconMessage />
        {t("MESSAGE_FROM_COMPANY")} ({offer?.Proposal?.Organization?.name})
      </div>
      <div className="seller-side_message-text">{offer?.message}</div>
    </React.Fragment>
  );
};

export default SellerSideProposalDescription;
