import React, {memo} from "react";

export const IconMedalBronzeEmpty = memo(({height, width, addClass, ...props}) => {
  return (
    <svg
      width={width || "20"}
      height={height || "20"}
      viewBox="0 0 20 20"
      fill="none"
      className={addClass}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.6575 4.56299H8.65747C8.38147 4.56299 8.15747 4.78699 8.15747 5.06299C8.15747 5.33899 8.38147 5.56299 8.65747 5.56299H10.1575V6.56299H8.65747C8.38147 6.56299 8.15747 6.78699 8.15747 7.06299C8.15747 7.33899 8.38147 7.56299 8.65747 7.56299H10.1575V8.56299H8.65747C8.38147 8.56299 8.15747 8.78699 8.15747 9.06299C8.15747 9.33899 8.38147 9.56299 8.65747 9.56299H10.6575C10.9335 9.56299 11.1575 9.33899 11.1575 9.06299V5.06299C11.1575 4.78699 10.9335 4.56299 10.6575 4.56299Z"
        fill="#A9A9A9"
      />
      <path
        fill-rule="evenodd"
        clipRule="evenodd"
        d="M11.349 1.79384L9.6574 0L7.96584 1.79384L5.5429 1.33682L5.22884 3.7823L3 4.83667L4.1834 6.9997L3 9.16273L5.22884 10.2171L5.5429 12.6626L7.96584 12.2056L9.6574 13.9994L11.349 12.2056L13.7719 12.6626L14.086 10.2171L16.3148 9.16273L15.1314 6.9997L16.3148 4.83667L14.086 3.7823L13.7719 1.33682L11.349 1.79384ZM12.9151 2.51602L10.9967 2.87787L9.6574 1.45757L8.31808 2.87787L6.39967 2.51602L6.15101 4.45227L4.38629 5.28709L5.32326 6.9997L4.38629 8.71232L6.15101 9.54713L6.39967 11.4834L8.31808 11.1215L9.6574 12.5418L10.9967 11.1215L12.9151 11.4834L13.1638 9.54713L14.9285 8.71232L13.9915 6.9997L14.9285 5.28709L13.1638 4.45227L12.9151 2.51602Z"
        fill="#A9A9A9"
      />
      <path
        d="M5.54736 12.6602L6.54736 12.3274V18.3548L9.65747 16.3507L12.7674 18.3548V12.3276L13.7674 12.6602L13.7673 20L9.65747 17.3507L5.54736 20V12.6602Z"
        fill="#A9A9A9"
      />
    </svg>
  );
});

export default IconMedalBronzeEmpty;
