import React, {useEffect, useState, useCallback} from "react";
import ReactDOM from "react-dom";
//local
import cn from "classnames";
import {notNull} from "helpers/helper";

export const LocalModal = ({
  title,
  titleIcon,
  children,
  onClose,
  className,
  style,
  isChildrenLoaded = true,
}) => {
  const clickListener = (keyEventName, func) =>
    document.addEventListener(keyEventName, func);
  const clickListenerRemove = (keyEventName, f) =>
    document.removeEventListener(keyEventName, f);

  const useDOMNode = () => {
    const [rect, setRect] = useState(null);
    const ref = useCallback((node) => notNull(node) && setRect(node), []);
    return [rect, ref];
  };
  const [rect, ref] = useDOMNode();

  const closeOnClickOutside = (event) => {
    const domNode = ReactDOM.findDOMNode(rect);
    isChildrenLoaded && domNode && !domNode?.contains(event.target) && onClose();
  };
  const closeOnEsc = (event) => event.keyCode === 27 && onClose();

  useEffect(() => {
    onClose &&
      setTimeout(() => {
        clickListener("click", closeOnClickOutside);
        clickListener("keydown", closeOnEsc);
      }, 0);
    return () => {
      onClose && clickListenerRemove("click", closeOnClickOutside);
      onClose && clickListenerRemove("keydown", closeOnEsc);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rect, isChildrenLoaded]);

  return (
    <div
      ref={ref}
      className={cn("modal-ent__wrapper __local", {[className]: !!className})}
      onClick={(e) => e.stopPropagation()}
      style={style}
    >
      {(title || titleIcon) && (
        <div className="modal-ent__title __local">
          {titleIcon}
          {title}
        </div>
      )}
      {children}
    </div>
  );
};
