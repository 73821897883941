import React, {useState} from "react";
import {Upload} from "antd";
import TextareaAutosize from "react-textarea-autosize";
// local
import {InitialsBadge} from "components/InitialsBadge";
import ProjectsSelect from "components/selects/ProjectsSelect";
import {ProjectLabel} from "components/ProjectLabel";
import {ButtonTransparent} from "components/buttons/ButtonTransparent";
import ButtonDelete from "components/buttons/ButtonDelete";
import {IconPlane, IconPaperClip} from "components/icons";
import {beforeUploadCustom} from "helpers/uploader";
import {LockValue} from "components/LockValue";
import {hasPermission} from "helpers/helper";
import i18next from "i18next";
import cn from "classnames";

const MessagePanel = ({
  message,
  mode = "view",
  loading,
  placeholder,
  noAttachDocument,
  canDeleteMessage,
  projects,
  noSelectProjectValue,
  highlightNoSelectProjectValue,
  project,
  allProjectItem,
  onDelete,
  isMyMessage,
  sendMessage,
  permissionsMessage,
  permissionsFile,
  addClass,
}) => {
  const [text, setText] = useState("");
  const [projectId, setProjectId] = useState(null);
  const [file, setFile] = useState(null);
  const language = i18next.language;
  const dateOptions = {
    month: "short",
    day: "numeric",
    weekday: "long",
    hour: "numeric",
    minute: "numeric",
  };
  const disabledSendButton =
    (!text.length && !file) || loading || (!!projects?.length ? !projectId : false);
  const isMineProject =
    !!project && project.Members?.map((member) => member.id).includes(message.User?.id);

  const ButtonUpload = () => (
    <ButtonTransparent
      icon={<IconPaperClip />}
      noBorder
      circular
      onClick={(e) =>
        !permissionsFile || hasPermission(permissionsFile) ? true : e.preventDefault()
      }
      addClass="pa-1 mb-1"
    />
  );

  const ButtonSend = () => (
    <ButtonTransparent
      disabled={disabledSendButton}
      icon={<IconPlane color={disabledSendButton && "#A9A9A9"} />}
      circular
      disabledTransparent
      addClass={`mb-2 ${!!projects?.length ? "" : "mla"}`}
      onClick={clearAndSend}
    />
  );

  const clearAndSend = () => {
    if (!!permissionsMessage && !hasPermission(permissionsMessage)) return;
    sendMessage(text, file, projectId);
    setText("");
    setFile(null);
    setProjectId(null);
  };

  return (
    <div className={cn("message-panel_wrapper", {[addClass]: !!addClass})}>
      <InitialsBadge
        firstName={message.User?.firstName}
        lastName={message.User?.lastName}
        large
        isCurrentUser={isMyMessage}
        addClass="mr-4 message-panel"
      />
      {mode === "create" && (
        <div className="message-panel_create-mode_wrapper">
          <TextareaAutosize
            maxRows="4"
            className="textarea"
            name="messageText"
            value={text}
            onChange={(e) => setText(e.target.value)}
            placeholder={placeholder}
          />
          <div className="tools __normal __nonecase">
            {!noAttachDocument && (
              <Upload
                customRequest={() => {}}
                beforeUpload={(file) =>
                  beforeUploadCustom(file, ["PNG", "JPG", "PDF", "XLS", "XLSX"], 5120)
                }
                onChange={(e) => setFile(e)}
                showUploadList={false}
                disabled={loading}
                className="tools-upload"
              >
                {permissionsFile ? (
                  <LockValue
                    canPermissions={permissionsFile}
                    needLabel
                    needShowModalToSubscribe
                    noLockIcon
                    dimmedWhenLocked
                    addClassIcon="mb-1 ml-0"
                  >
                    <ButtonUpload />
                  </LockValue>
                ) : (
                  <ButtonUpload />
                )}

                {file?.file?.name && (
                  <a
                    href="/"
                    onClick={(e) => e.preventDefault()}
                    rel="noopener noreferrer"
                    style={{marginBottom: "5px"}}
                  >
                    {file?.file?.name}
                  </a>
                )}
              </Upload>
            )}

            {!!projects?.length && (
              <ProjectsSelect
                list={!!allProjectItem ? [allProjectItem, ...projects] : projects}
                onChange={setProjectId}
                noBorder
                selectedValue={projectId}
                highlightIfNoSelectValue={highlightNoSelectProjectValue}
                noSelectValue={noSelectProjectValue}
                highlightMyProjectsId={message.User?.id}
                addClass="mt-2 pr-0 mla"
              />
            )}
            {permissionsMessage ? (
              <LockValue
                canPermissions={permissionsMessage}
                needLabel
                needShowModalToSubscribe
                noLockIcon
                dimmedWhenLocked
                addClassWrapper="mla"
                addClassIcon={`mb-2 ${disabledSendButton ? "ml-0" : ""}`}
              >
                <ButtonSend />
              </LockValue>
            ) : (
              <ButtonSend />
            )}
          </div>
        </div>
      )}
      {mode === "view" && (
        <React.Fragment>
          <div className="message-panel_view-mode_wrapper">
            <div className="message-panel_view-mode_date df">
              {new Date(message?.createdAt).toLocaleString(language, dateOptions)}
              {!!project && (
                <ProjectLabel
                  name={project.name}
                  id={project.id}
                  color={!isMineProject && "discreteText"}
                  width="400"
                  addClass="mla"
                />
              )}
            </div>
            <div className="pt-2">{message.message}</div>
            {message?.File?.name && (
              <div className="tools">
                <IconPaperClip />
                <a
                  href={message.File?.url || "/"}
                  target="_blank"
                  className="ml-1"
                  rel="noopener noreferrer"
                >
                  {message.File?.name}
                </a>
              </div>
            )}
          </div>
          {canDeleteMessage && (
            <ButtonDelete activeOnHover addClass="ml-4 prl-0" onClick={onDelete} />
          )}
        </React.Fragment>
      )}
    </div>
  );
};

export default MessagePanel;
