// import React, {useEffect, useMemo, useState} from "react";
import React, {useMemo} from "react";
import {useTranslation} from "react-i18next";
// local
import Favorite from "../../Favorite";
import MonitorSuppliers from "../../MonitorSuppliers";
import {ButtonTransparent} from "components/buttons/ButtonTransparent";
import PresentationPageHeaderDropdown from "./PresentationPageHeaderDropdown";
import {formatCompanyNumber, hasPermission} from "helpers/helper";
import {LockValue} from "components/LockValue";

import {IconUE2021, IconRamavtal, IconEdit} from "components/icons";
import {ourAgreementsTypes} from "constants/user.consts";
import {inject, observer} from "mobx-react";

const PresentationPageHeaderMain = inject("store")(
  observer(({store: {organization, auth, clientStore}, edit, setEdit}) => {
    const {organizationDetail} = organization;
    const {user, activeOrganization} = auth;
    const {t} = useTranslation();

    const companyId = useMemo(
      () => organizationDetail.CompanyId,
      [organizationDetail.CompanyId]
    );

    const isUE2021 = !!organizationDetail.OurAgreements?.find(
      (item) => item?.type === ourAgreementsTypes.UE2021
    );
    const isRamavtal = !!organizationDetail.OurAgreements?.find(
      (item) => item?.type === ourAgreementsTypes.RAMAVTAL
    );
    const isMonitored = useMemo(
      () =>
        !!organizationDetail.Company.OrgMemberMonitor.map((member) => member.User).find(
          (item) => item.id === user.id
        ),
      [organizationDetail, user]
    );
    const isActive = useMemo(() => organizationDetail?.isActive, [organizationDetail]);

    const openEdit = () =>
      hasPermission(["EDIT_OUR_PROFILE_MAIN_INFO"]) && setEdit({...edit, main: true});

    const handleChangeMonitorSuppliers = (checked) =>
      checked
        ? clientStore.setUserMonitoredCompany(companyId)
        : clientStore.removeUserMonitoredCompany(companyId);

    return (
      <div className="presentation-page-header__right-side">
        <div className="presentation-page-header__titles">
          <div className="presentation-page-header__about-org">
            <div className="presentation-page-header__about-org__name-number df-column">
              <h1 className="presentation-page-header__name presentation-page-header__not-edit __capitalize">
                {organizationDetail?.name}
              </h1>
              <div className="df-row">
                <span className="presentation-page-header__info-org">
                  {organizationDetail?.Company?.companyName}
                </span>
                <span className="presentation-page-header__info-org">
                  {formatCompanyNumber(organizationDetail?.Company?.companyNumber)}
                </span>
              </div>
            </div>
            <div className="meta df-row">
              {!isActive && activeOrganization.allowMonitorSuppliers && (
                <MonitorSuppliers
                  active={isMonitored}
                  onChange={handleChangeMonitorSuppliers}
                  changedPerson={user}
                  currentUser={user}
                />
              )}
              {!isActive && <Favorite />}

              {(isUE2021 || isRamavtal) && (
                <span className="icons df-row-center">
                  {isUE2021 && <IconUE2021 />}
                  {isRamavtal && <IconRamavtal addClass="ml-2" />}
                </span>
              )}

              {!isActive && (
                <div className="presentation-page-header__select pos-r">
                  <PresentationPageHeaderDropdown />
                </div>
              )}
            </div>

            {isActive && (
              <div className="df-row-start">
                <LockValue
                  canPermissions={["EDIT_OUR_PROFILE_MAIN_INFO"]}
                  needLabel
                  needShowModalToSubscribe
                  noLockIcon
                  dimmedWhenLocked
                  addClassIcon="ml-0"
                >
                  <ButtonTransparent
                    icon={<IconEdit />}
                    noBorder
                    addClass="pa-0"
                    onClick={openEdit}
                  />
                </LockValue>

                <div className="edit-tip df-row-center">
                  <span className="emoji">👈</span>
                  {t("ADD_DESCRIPTION_TEXT_2")}
                </div>
              </div>
            )}
          </div>
        </div>
        {isActive && organizationDetail?.description === "" ? (
          <div className="description-placeholder">
            <div className="left">{t("ADD_DESCRIPTION_TEXT_1")}</div>
          </div>
        ) : (
          <div>{organizationDetail?.description}</div>
        )}
      </div>
    );
  })
);

export default PresentationPageHeaderMain;
