import React, {useState, useMemo} from "react";
import {useTranslation} from "react-i18next";
//local
import {Modal} from "components/Modal";
import {InfoTooltip} from "components/InfoTooltip";
import MultipleSelect from "components/selects/MultipleSelect";
import SelectionRow from "components/selects/SelectionRow";
import {publicRfpsFilterRow} from "constants/tabs.const";
import {IconFunnel, IconMailLetter} from "components/icons";
import {ButtonTransparent} from "components/buttons/ButtonTransparent";
import {color} from "constants/color.consts";

export const PublicRfpFilterSettingsModal = ({
  initialFilterSettings,
  maxCategories,
  maxRegions,
  email,
  saveLoading,
  onSave,
  onClose,
}) => {
  const {t} = useTranslation();
  const [filterSettings, setFilterSettings] = useState(initialFilterSettings ?? {});

  const filterRowItems = useMemo(
    () =>
      Object.entries(filterSettings)
        .filter((item) => Array.isArray(item[1]))
        .map((item) => item[1].map((value) => [item[0], value]))
        .flat()
        .sort((a, b) => (a[0] > b[0] ? -1 : 1)),
    [filterSettings]
  );

  const regionsNumber = useMemo(
    () => filterSettings?.availableIn?.length ?? 0,
    [filterSettings?.availableIn?.length]
  );
  const categoriesNumber = useMemo(
    () => filterRowItems.length - regionsNumber,
    [filterRowItems.length, regionsNumber]
  );
  const notAddRegions = useMemo(
    () => regionsNumber >= maxRegions,
    [regionsNumber, maxRegions]
  );
  const notAddCategories = useMemo(
    () => categoriesNumber >= maxCategories,
    [categoriesNumber, maxCategories]
  );

  const disableSaveButton = useMemo(
    () => !((regionsNumber && categoriesNumber) || (!regionsNumber && !categoriesNumber)),
    [regionsNumber, categoriesNumber]
  );

  const onSelectChange = (name, value, isDeleting) => {
    const params = {...filterSettings, [name]: value};
    const isSelectingRegions = name === "availableIn";
    const isLessCategories =
      !isSelectingRegions &&
      Object.entries(params)
        .filter((item) => item[0] !== "availableIn")
        .filter((item) => Array.isArray(item[1]))
        .reduce((filter, item) => filter.concat(item[1]), []).length >= categoriesNumber;
    const isLessRegions = isSelectingRegions && value.length > regionsNumber;
    if (!isDeleting && notAddCategories && isLessCategories) return;
    if (!isDeleting && notAddRegions && isLessRegions) return;
    setFilterSettings(params);
  };

  const handleRemoveFilterValue = (value) => {
    const deletedName = filterRowItems.find((item) => item[1] === value)?.[0];
    const deletedValue = filterRowItems
      .filter((item) => item[0] === deletedName && item[1] !== value)
      .map((item) => item[1]);
    deletedName && onSelectChange(deletedName, deletedValue, true);
  };

  return (
    <Modal
      onClose={onClose}
      width="min"
      boldTitle
      noCenterContent
      titleIcon={<IconMailLetter width="21" height="13" addClass="mr-4" />}
      titleText="PUBLIC_RFPS_PAGE_FILTER_SETTINGS_MODAL_TITLE"
      contentClassName="public-rfps__filter-settings-modal"
    >
      <div className="pa-8">
        <span className="df pb-8">
          {t("PUBLIC_RFPS_PAGE_FILTER_SETTINGS_MODAL_DESCRIPTION").format(email)}
        </span>
        <div className="df-row-center">
          <IconFunnel height="30" width="30" color={color.green} addClass="mr-4" />
          {publicRfpsFilterRow.map((selection) => (
            <MultipleSelect
              key={selection.label}
              value={filterSettings?.[selection.label]}
              list={selection.array}
              customSelectAllElement={selection.selectAll}
              tiny
              addClass="mr-2"
              uppercaseButton
              notShowingSelections
              needSearch={selection.isNeedSearch}
              color={selection.color}
              bottomElement={selection.bottomElement}
              onChange={(value) => onSelectChange(selection.label, value)}
              disableRegions={notAddRegions}
              disableCategories={notAddCategories}
              title={selection.title}
              requestParam={selection.label}
              titleInButton
            />
          ))}
          {(notAddRegions || notAddCategories) && (
            <InfoTooltip
              tooltip={t("ERROR_OPEN_RFT_MONITOR_MAX_REGIONS_CATEGORIES_EXCEED").format(
                maxCategories,
                maxRegions
              )}
              centerText
            />
          )}
        </div>
        {!!Object.keys(filterSettings).length && (
          <SelectionRow
            values={filterRowItems.map((item) => ({
              value: item[1],
              label: item[1],
              color: item[1].includes("REGION") ? "blue" : "green",
            }))}
            tiny
            addClass="mt-2 ml-10"
            onRemove={handleRemoveFilterValue}
          />
        )}
        <div className="df-row-jce-center mt-4">
          <ButtonTransparent name="CANCEL" medium color="red" onClick={onClose} />
          <ButtonTransparent
            name="SAVE"
            medium
            addClass="ml-4"
            loading={saveLoading}
            disabled={disableSaveButton}
            onClick={() => onSave(filterSettings)}
          />
        </div>
      </div>
    </Modal>
  );
};
