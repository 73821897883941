import React, {useState} from "react";
import {Select} from "antd";
import {useNavigate} from "react-router-dom";
import {inject, observer} from "mobx-react";
// local
import {orderingNameValues} from "../../constants/select.consts";
import {changeSelectValue, changeFilterParams, getUrlParams} from "helpers/helper";
import {useTranslation} from "react-i18next";
import CustomRadioSwitch from "components/CustomRadioSwitch";
import {InfoTooltip} from "components/InfoTooltip";
import {ButtonTransparent} from "components/buttons/ButtonTransparent";
import ButtonSelect from "components/selects/ButtonSelect";
import {InitialsBadge} from "components/InitialsBadge";
import {} from "helpers/helper";
import {
  IconRefresh,
  IconSpeedometer,
  IconCommunity,
  IconFirstAddKit,
  IconTabletPen,
  IconMailLetter,
} from "../icons/index";
import {turnoverButtonSelect, creditRatingButtonSelect} from "constants/select.consts";
import {color} from "constants/color.consts";

const {Option} = Select;

// place: common, favorites, blacklist, monitoring, qualifying

const OrganizationCardHead = inject("store")(
  observer(
    ({
      store: {organization},
      onChangeSort,
      isHistory,
      place,
      specificData,
      onChangeShowHistory,
      customParams,
      showTotalItems, // Show/dont show the counter widget
      filledFilters,
    }) => {
      const {t} = useTranslation();
      const navigate = useNavigate();
      const {
        filterParams,
        suppliers: {totalItems},
      } = organization;

      const [turnover, setTurnover] = useState(+(filterParams.minTurnover ?? 0));
      const [creditRating, setCreditRating] = useState(
        +(filterParams.minCreditRating ?? 0)
      );

      const onChangeOrder = (value) => {
        const params = changeFilterParams(value);
        onChangeSort("direction", params);
      };

      const options = orderingNameValues.map(({value, label}, i) => (
        <Option key={i} value={value}>
          {t(label)}
        </Option>
      ));

      const updateUrlParams = (search) => navigate({search});

      const onSelectChangeHandler = (name, value) => {
        if (name === "minTurnover") setTurnover(value);
        if (name === "minCreditRating") setCreditRating(value);
        const params = {...filterParams, [name]: !!value ? value : undefined};
        if (!value) delete params[name];
        updateUrlParams(getUrlParams(params));
        organization.setFilterParams({...params, ...customParams});
        organization.searchSuppliers();
      };

      return (
        <div className="organization-card_head">
          <div className="organization-card_head__name __wrapper">
            <div className="organization-card_head__name __select df-row-center __bold">
              {place === "monitoring" ? (
                <div className="df-row-center mt-1 mb-1">
                  <span className="fz-12 __uppercase">{t("COMPANY")}</span>
                  <span className="__normal fz-12 ml-8 mr-2">
                    {specificData?.monitoringCompanyCount ?? ""}
                  </span>
                  <InfoTooltip tooltip="MONITORING_TAB_COMPANY_TOOLTIP" />
                </div>
              ) : (
                <Select
                  onChange={onChangeOrder}
                  value={changeSelectValue(orderingNameValues, filterParams)}
                  className="__bold"
                >
                  {options}
                </Select>
              )}
              {!!onChangeShowHistory && (
                <CustomRadioSwitch
                  checked={isHistory}
                  uppercase
                  label={t("HISTORY")}
                  small
                  addWrapperClass="ml-1"
                  isActive={onChangeShowHistory}
                />
              )}
              {showTotalItems && (
                <div className="totals ml-8 df-row-center">
                  <span>
                    {filledFilters && `${t("FILTERED_LABEL")} `}
                    {t("TOTALS_AMOUNT")}: {totalItems}
                  </span>
                </div>
              )}
            </div>
          </div>

          <div className="organization-card_head__indicator __wrapper">
            {!(place === "qualifying" || place === "monitoring") && (
              <div className="organization-card_head__indicator __rating __uppercase __bold">
                {t("OUR_RATING")}
              </div>
            )}
            {place !== "monitoring" && (
              <React.Fragment>
                <div className="organization-card_head__indicator __loading">
                  <ButtonSelect
                    list={turnoverButtonSelect}
                    value={turnover}
                    buttonName={
                      <div className="lh-11">
                        {turnover}
                        <div className="fz-8">M</div>
                      </div>
                    }
                    buttonIcon={<IconRefresh color={color.blue} />}
                    buttonTooltip="YEARLY_TURNOVER"
                    buttonHoverableLink
                    small
                    addWrapperClass="mla"
                    dropdownClassName="__turnover"
                    onChange={({value}) => onSelectChangeHandler("minTurnover", value)}
                  />
                </div>
                <div className="organization-card_head__indicator __status">
                  <ButtonSelect
                    list={creditRatingButtonSelect}
                    value={creditRating}
                    buttonName={creditRating}
                    buttonIcon={<IconSpeedometer color={color.blue} />}
                    buttonTooltip="CREDIT_RATING"
                    buttonHoverableLink
                    small
                    addWrapperClass="mla"
                    dropdownClassName="__credit-rating"
                    onChange={({value}) =>
                      onSelectChangeHandler("minCreditRating", value)
                    }
                  />
                </div>
                <div className="organization-card_head__indicator __sub-status">
                  <ButtonTransparent
                    icon={<IconCommunity color={color.blue} />}
                    tooltip="HAS_COLL_AGREEMENT"
                    iconButton
                    hoverableLink
                    nonTransparent={!!filterParams.collectiveUnionAgreement}
                    onClick={() =>
                      onSelectChangeHandler(
                        "collectiveUnionAgreement",
                        !filterParams.collectiveUnionAgreement
                      )
                    }
                  />
                </div>
                <div className="organization-card_head__indicator __sub-status">
                  <ButtonTransparent
                    icon={<IconFirstAddKit color={color.blue} />}
                    tooltip="HAS_INSURANCE"
                    iconButton
                    hoverableLink
                    nonTransparent={!!filterParams.insurance}
                    onClick={() =>
                      onSelectChangeHandler("insurance", !filterParams.insurance)
                    }
                  />
                </div>
                <div className="organization-card_head__indicator __sub-status">
                  <ButtonTransparent
                    icon={<IconTabletPen color={color.blue} />}
                    tooltip="HAS_FTAX_VAT_IS_REGISTER"
                    iconButton
                    hoverableLink
                    nonTransparent={!!filterParams.FVREmployer}
                    onClick={() =>
                      onSelectChangeHandler("FVREmployer", !filterParams.FVREmployer)
                    }
                  />
                </div>
              </React.Fragment>
            )}

            {place === "monitoring" && (
              <React.Fragment>
                <div className="organization-card_head__indicator __colleagues __uppercase fz-12 __bold">
                  <IconMailLetter width="19" height="12" />
                  <span className="ml-2 mr-2">{t("MONITORING_TAB_COLLEAGUES")}</span>
                  <InfoTooltip tooltip="MONITORING_TAB_COLLEAGUES_TOOLTIP" />
                </div>
                <div className="organization-card_head__indicator __my __uppercase">
                  <IconMailLetter width="19" height="12" />
                  <InitialsBadge
                    firstName={specificData?.user?.firstName}
                    lastName={specificData?.user?.lastName}
                    isCurrentUser
                    addClass="mr-2 ml-2"
                  />
                  <InfoTooltip tooltip="MONITORING_TAB_MY_TOOLTIP" />
                </div>
              </React.Fragment>
            )}

            {place === "qualifying" && (
              <div className="organization-card_head__indicator __answers __uppercase">
                {t("QUALIFYING_TAB_ANSWERS")}
              </div>
            )}
          </div>
        </div>
      );
    }
  )
);

export default OrganizationCardHead;
