import React, {useEffect, useState, useMemo} from "react";
import {inject, observer} from "mobx-react";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
//local
// import {VideoInstructionLink} from "components/VideoInstructionLink";
import {Layout} from "components/UI/layout";
import {ButtonTransparent} from "components/buttons/ButtonTransparent";
import MiniSelect from "components/selects/MiniSelect";
import {IconBriefcase, IconPlus} from "components/icons";
import {ProjectModal} from "components/modals/ProjectModal";
import {Tabs} from "components/Tabs";
import {projectsTabs} from "constants/tabs.const";
import {hasPermission, notUndefined, isUndefined} from "helpers/helper";
import {ConfirmModal} from "components/ConfirmModal";

const OurProjectsPage = inject("store")(
  observer(({store: {auth, buyer, organization}}) => {
    const {user} = auth;
    const {activeProjectsTab, isNeedNewProjectButton, filterCurrentTabProjects} = buyer;
    const {departments, projectsDropdownDepartments, currentDepartmentId} = organization;
    const {t} = useTranslation();
    const navigate = useNavigate();
    const [isProjectModal, setIsProjectModal] = useState(null);
    const activeTabRequestKey = useMemo(
      () => projectsTabs.find((tab) => tab.id === activeProjectsTab)?.requestKey,
      [activeProjectsTab]
    );

    const handleClickNewProject = () =>
      hasPermission(["CREATE_PROJECT"])
        ? setIsProjectModal("new-project")
        : ConfirmModal({
            title: t("WANT_START_PROJECT"),
            text: t("CANT_START_PROJECT"),
            okText: "READ_AND_UPGRADE",
            type: "warning",
            onOk: () => navigate({hash: "#your-subscription"}),
          });

    const handleClose = (needUpdateProject) => {
      setIsProjectModal(null);
      if (typeof needUpdateProject === "number") {
        notUndefined(activeTabRequestKey) &&
          buyer.getProjects(false, activeTabRequestKey);
        buyer.setActiveProjectsTab(needUpdateProject);
      }
    };

    useEffect(() => {
      notUndefined(activeTabRequestKey) && buyer.getProjects(false, activeTabRequestKey);
      organization.getOrganizationDepartmentLabels();
    }, [buyer, organization, activeTabRequestKey]);

    useEffect(() => {
      organization.getOrganizationMembers("SHOW_PROJECT_SETTINGS_TEAM_USER");
      isUndefined(activeTabRequestKey) &&
        buyer.setActiveProjectsTab(
          hasPermission(["ENABLE_PROJECTS_MINE_DEFAULT_TAB"]) ? 0 : 1
        );
    }, [buyer, organization, activeTabRequestKey]);

    return (
      <Layout>
        <div className="our-projects">
          <div className="content-block_header">
            <IconBriefcase />
            <h3 className="content-block_title __uppercase">{t("PROJECTS")}</h3>
            {!!departments && user.ActiveOrganization?.useDepartmentsForProjects && (
              <MiniSelect
                list={projectsDropdownDepartments}
                defaultValue={currentDepartmentId}
                addClass="ml-4"
                itemAsObject
                onChange={(value) => auth.setLastUsedDepartmentLabel(value)}
                label={filterCurrentTabProjects?.length ?? 0}
                tinyLabel
                boldLabel
              />
            )}
            {/*
            <VideoInstructionLink
              label="VIDEO_LINK_PROJECTS_LABEL"
              link="VIDEO_LINK_PROJECTS_LINK"
              addClass="ml-8"
            />
            */}
            {isNeedNewProjectButton && (
              <ButtonTransparent
                addClass="our-projects_head__btn"
                name="NEW_PROJECT"
                type="button"
                icon={<IconPlus />}
                onClick={handleClickNewProject}
              />
            )}
          </div>
          <Tabs
            tabs={projectsTabs}
            active={activeProjectsTab}
            setActive={(id) => buyer.setActiveProjectsTab(id)}
            addClass="our-projects_content"
          />
          {isProjectModal && (
            <ProjectModal projectId={isProjectModal} onClose={handleClose} />
          )}
        </div>
      </Layout>
    );
  })
);

export default OurProjectsPage;
