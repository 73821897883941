import React, {memo} from "react";

export const IconMedalGoldEmpty = memo(({height, width, addClass, ...props}) => {
  return (
    <svg
      width={width || "20"}
      height={height || "20"}
      viewBox="0 0 20 20"
      fill="none"
      className={addClass}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.7041 9.56384C9.4281 9.56384 9.2041 9.33984 9.2041 9.06384V5.56384H8.7041C8.4281 5.56384 8.2041 5.33984 8.2041 5.06384C8.2041 4.78784 8.4281 4.56384 8.7041 4.56384H9.7041C9.9801 4.56384 10.2041 4.78784 10.2041 5.06384V9.06384C10.2041 9.33984 9.9801 9.56384 9.7041 9.56384Z"
        fill="#A9A9A9"
      />
      <path
        fill-rule="evenodd"
        clipRule="evenodd"
        d="M11.349 1.79469L9.6574 0.000854492L7.96584 1.79469L5.5429 1.33768L5.22884 3.78316L3 4.83753L4.1834 7.00056L3 9.16358L5.22884 10.218L5.5429 12.6634L7.96584 12.2064L9.6574 14.0003L11.349 12.2064L13.7719 12.6634L14.086 10.218L16.3148 9.16358L15.1314 7.00056L16.3148 4.83753L14.086 3.78316L13.7719 1.33768L11.349 1.79469ZM12.9151 2.51688L10.9967 2.87873L9.6574 1.45842L8.31808 2.87873L6.39967 2.51688L6.15101 4.45312L4.38629 5.28794L5.32326 7.00056L4.38629 8.71317L6.15101 9.54799L6.39967 11.4842L8.31808 11.1224L9.6574 12.5427L10.9967 11.1224L12.9151 11.4842L13.1638 9.54799L14.9285 8.71317L13.9915 7.00056L14.9285 5.28794L13.1638 4.45312L12.9151 2.51688Z"
        fill="#A9A9A9"
      />
      <path
        d="M5.54736 12.661L6.54736 12.3282V18.3557L9.65747 16.3516L12.7674 18.3556V12.3284L13.7674 12.6611L13.7673 20.0009L9.65747 17.3516L5.54736 20.0009V12.661Z"
        fill="#A9A9A9"
      />
    </svg>
  );
});

export default IconMedalGoldEmpty;
