import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {ButtonTransparent} from "components/buttons/ButtonTransparent";
import {IconClose} from "components/icons";
import {color} from "constants/color.consts";
import cn from "classnames";

export const ExplanationBubble = ({
  title,
  text,
  width = "fit-content",
  minWidth = "300px",
  textCenter,
  fontSize = "12px",
  background = color.white,
  position = "relative",
  top,
  bottom,
  right,
  left,
  pointTo,
  addClass,
}) => {
  const {t} = useTranslation();
  const [isVisible, setIsVisible] = useState(true);

  const directions = ["topLeft", "topRight", "bottomRight", "bottomLeft"];
  const getBorderRadius = () =>
    directions.reduce((acc, item) => acc.concat(item === pointTo ? "0 " : "10px "), "");

  const customStyles = {
    width: width,
    minWidth: minWidth,
    background: background,
    position: position,
    borderRadius: getBorderRadius(),
    ...(top && {top}),
    ...(bottom && {bottom}),
    ...(right && {right}),
    ...(left && {left}),
  };

  return isVisible ? (
    <div
      className={cn("explanation-bubble", {[addClass]: !!addClass})}
      style={customStyles}
    >
      <div className="df-row-jsb-start mb-2">
        {title && <span className="__bold">{t(title)}</span>}
        <ButtonTransparent
          icon={<IconClose />}
          iconButton
          addClass="ml-8 pa-0"
          onClick={() => setIsVisible(false)}
        />
      </div>

      <span
        className={cn("explanation-bubble__text", {"__text-center": !!textCenter})}
        style={{fontSize}}
      >
        {t(text)}
      </span>
    </div>
  ) : null;
};
