import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import TextareaAutosize from "react-textarea-autosize";
import {v4 as uuidv4} from "uuid";
//local
import {Input} from "components/Input";
import ProposalItemBudget from "./ProposalItemBudget";
import {IconPushPin} from "components/icons";
import {MedalsRating} from "components/MedalsRating";
import {adjustmentSymbolsAndColors} from "constants/buyer.consts";
import FinancialSnapshotSelect from "components/selects/FinancialSnapshotSelect";
import {offerToMedalRating} from "constants/buyer.consts";
import {hasPermission} from "helpers/helper";
import {LockValue} from "components/LockValue";
import cn from "classnames";
import {createArray} from "helpers/helper";
import {color} from "constants/color.consts";

const ManualProposalItem = ({
  proposal,
  language,
  adjustmentsIds,
  answersCount,
  budgetValue,
  isShowAdjustments,
  isShowAnswers,
  isShowChecks,
  checkIds,
  clickable,
  isHovered,
  onClick,
  onMouseEnter,
  onMouseLeave,
  onChangeAdjustment,
  onChangeNote,
  onChangeCheck,
  onChangeRating,
  publicPageMode,
}) => {
  const {t} = useTranslation();
  const checkValues = proposal.checkValues ?? [];
  const offerCompanyNumber = proposal.companyNumber;
  const snapshots = proposal?.CompanyCreditSafePdfReports;

  const [currentAdjustmentValues, setCurrentAdjustmentValues] = useState(
    proposal.adjustmentValues ?? []
  );
  const isPriceAdjustments = !!currentAdjustmentValues?.length;
  const adjustmentValue = isPriceAdjustments
    ? currentAdjustmentValues.reduce((sum, item) => sum + item.value, 0)
    : 0;

  const [currentCheckValue, setCurrentCheckValue] = useState(null);
  const [currentBuyerNote, setCurrentBuyerNote] = useState(proposal.buyerNote);

  const canSetRanking = hasPermission(["ENABLE_RFT_OFFERS_OFFER_RANKING"]);
  const canEditAdjustments = hasPermission(["CREATE_RFT_OFFERS_ADJUSTMENTS"]);
  const canEditChecks = hasPermission(["CREATE_RFT_OFFERS_CHECKS"]);
  const canEditNotes = hasPermission(["EDIT_RFT_OFFERS_NOTES"]);
  const canViewManualProposal = hasPermission(["READ_RFT_OFFERS_MANUAL_OFFER"]);

  const choice = (value) => (!value ? "equally" : value > 0 ? "more" : "less");

  const getNewValues = (values, value, titleId, key) => {
    const hasValue = values.some((item) => item[key] === titleId);
    const newValue = {
      id: uuidv4(),
      [key]: titleId,
      value: value,
    };
    const newValues = hasValue
      ? values.map((item) => (item[key] === titleId ? newValue : item))
      : [...values, newValue];
    return newValues;
  };

  return (
    <LockValue
      canPermissions={["READ_RFT_OFFERS_MANUAL_OFFER"]}
      needLabel
      needShowModalToSubscribe
      needRightIcon={false}
    >
      <div
        className={cn("proposals-tab_body __manual-offer", {__clickable: clickable})}
        onClick={() => clickable && canViewManualProposal && onClick()}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <div className="proposals-tab_body__row __name">
          <div className="df-row-jsb-center full-width hght-32">
            <IconPushPin width="14" height="14" addClass="mr-1" color={color.black} />
            {proposal.organizationName}
            <LockValue
              addClassIcon="ml-0"
              canPermissions={["ENABLE_RFT_OFFERS_OFFER_RANKING"]}
              needLabel
              needShowModalToSubscribe
              noLockIcon
              dimmedWhenLocked
            >
              <MedalsRating
                place={offerToMedalRating[proposal.medal]}
                shortView={!isHovered}
                onClick={(e, place) => {
                  if (canSetRanking) {
                    e.stopPropagation();
                    onChangeRating("manual", place, proposal.id);
                  } else e.preventDefault();
                }}
                addClass="mla pl-2"
              />
            </LockValue>
          </div>
          {!publicPageMode && (
            <FinancialSnapshotSelect
              companyNumber={offerCompanyNumber}
              initialSnapshots={snapshots}
              addClass="hght-32"
            />
          )}
        </div>
        <ProposalItemBudget
          adjustmentValues={proposal.adjustmentValues}
          price={proposal.price}
          budgetValue={budgetValue}
          language={language}
          isHidedInfo={false}
        />
        <div className="proposals-tab_body__row __no-divider"></div>
        <div className="proposals-tab_body__row mt-2 __no-divider __bold">
          {adjustmentSymbolsAndColors[choice(adjustmentValue)].symbol}{" "}
          {adjustmentValue?.toLocaleString(language ?? "sv")}
        </div>
        {isShowAdjustments &&
          !!adjustmentsIds?.length &&
          adjustmentsIds.map((id, i) => {
            const value = currentAdjustmentValues.find(
              (adj) => adj.adjustmentTitleId === id
            )?.value;
            return isHovered ? (
              <div
                key={`${id}_${proposal.id}`}
                onClick={(e) => e.stopPropagation()}
                className="proposals-tab_body__row __no-divider"
              >
                <LockValue
                  canPermissions={["CREATE_RFT_OFFERS_ADJUSTMENTS"]}
                  needLabel
                  addClassWrapper="fg2"
                  needShowModalToSubscribe
                  noLockIcon
                >
                  <Input
                    tiny
                    flatView
                    fullWidth
                    type="number"
                    customHeight="26"
                    fontSize="12"
                    placeholder={t(
                      "RFP_PROPOSALS_LIST_ADJUSTMENTS_COLUMN_NOTE_PLACEHOLDER"
                    )}
                    className="input pl-2"
                    value={value}
                    onChange={(e) => {
                      if (canEditAdjustments) {
                        const newValues = getNewValues(
                          currentAdjustmentValues,
                          e.target.value ? +e.target.value : null,
                          id,
                          "adjustmentTitleId"
                        );

                        setCurrentAdjustmentValues(newValues);
                        onChangeAdjustment("manual", newValues, proposal.id);
                      }
                    }}
                  />
                </LockValue>
              </div>
            ) : (
              <div
                key={i}
                className={`proposals-tab_body__row __inner${
                  i === adjustmentsIds?.length - 1 ? " __no-divider" : ""
                }`}
              >
                {adjustmentSymbolsAndColors[choice(value)].symbol}{" "}
                {value?.toLocaleString(language ?? "sv") ?? 0}
              </div>
            );
          })}
        {!publicPageMode && isShowAdjustments && (
          <div className="proposals-tab_body__row __no-divider"></div>
        )}
        <div className="proposals-tab_body__row __no-divider mt-2"> </div>
        {isShowAnswers &&
          createArray(answersCount).map((_, i) => (
            <div
              key={i}
              className={`proposals-tab_body__row ${
                i === answersCount - 1 ? "__no-divider" : ""
              }`}
            />
          ))}
        <div className="proposals-tab_body__row __no-divider mt-2" />
        {isShowChecks &&
          checkIds.map((id, i) => {
            const value = checkValues.find((adj) => adj.checkTitleId === id)?.value;
            return (
              <div
                key={`${id}_${proposal.id}`}
                className={cn("proposals-tab_body__row __question-row", {
                  "__no-divider": !publicPageMode || ++i === checkIds.length,
                })}
                onClick={(e) => e.stopPropagation()}
              >
                {publicPageMode ? (
                  value
                ) : (
                  <LockValue
                    canPermissions={["CREATE_RFT_OFFERS_CHECKS"]}
                    needLabel
                    addClassWrapper="fg2"
                    needShowModalToSubscribe
                  >
                    <Input
                      tiny
                      flatView
                      fullWidth
                      customHeight="26"
                      fontSize="12"
                      placeholder={t("RFP_PROPOSALS_LIST_CHECKS_COLUMN_NOTE_PLACEHOLDER")}
                      className="input pl-2"
                      value={
                        currentCheckValue?.checkTitleId === id
                          ? currentCheckValue?.value
                          : value ?? ""
                      }
                      onChange={(e) => {
                        if (canEditChecks) {
                          setCurrentCheckValue({checkTitleId: id, value: e.target.value});
                          onChangeCheck(
                            "manual",
                            [
                              ...checkValues.filter((check) => check.checkTitleId !== id),
                              {
                                id: uuidv4(),
                                checkTitleId: id,
                                value: e.target.value,
                              },
                            ],
                            proposal.id
                          );
                        }
                      }}
                      onFocus={(e) =>
                        setCurrentCheckValue({checkTitleId: id, value: e.target.value})
                      }
                      onFocusLeave={() => setCurrentCheckValue(null)}
                    />
                  </LockValue>
                )}
              </div>
            );
          })}
        {!publicPageMode && isShowChecks && (
          <div className="proposals-tab_body__row __no-divider" />
        )}
        <div
          className="proposals-tab_body__row __notes"
          onClick={(e) => e.stopPropagation()}
        >
          <LockValue
            canPermissions={["EDIT_RFT_OFFERS_NOTES"]}
            needLabel
            addClassWrapper="fg2"
            needShowModalToSubscribe
          >
            <TextareaAutosize
              maxRows="3"
              className="textarea"
              name="messageText"
              value={currentBuyerNote}
              disabled={!onChangeNote}
              onChange={(e) => {
                if (canEditNotes) {
                  setCurrentBuyerNote(e.target.value);
                  !!onChangeNote && onChangeNote("manual", e.target.value, proposal.id);
                }
              }}
              placeholder={t("RFP_PROPOSALS_LIST_NOTES_COLUMN_NOTE_PLACEHOLDER")}
            />
          </LockValue>
        </div>
      </div>
    </LockValue>
  );
};

export default ManualProposalItem;
