import React, {useState, useEffect, useCallback} from "react";
import {inject, observer} from "mobx-react";
import {useTranslation} from "react-i18next";
//local
import {Preloader} from "components/Preloader";
import MessagePanel from "components/MessagePanel";
import {showSuccess} from "helpers/notifications.helper";

const MessagesSection = inject("store")(
  observer(({store: {auth, seller, clientStore}}) => {
    const {user: authUser} = auth;
    const {request} = seller;
    const [messages, setMessages] = useState([]);
    const [loading, setLoading] = useState(false);
    const [innerLoading, setInnerLoading] = useState(false);
    const {t} = useTranslation();

    const loadMessages = useCallback(() => {
      setLoading(true);
      request.id &&
        seller
          .getRfpMessages({rfpId: request.id})
          .then((data) => {
            // update unreadMessageCount near Messages tab
            seller.updateRequestInfo({
              ...request,
              MessagesStat: {...request.MessagesStat, unreadMessageCount: 0},
            });
            setMessages(data.messages);
          })
          .finally(() => setLoading(false));
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const clickSend = async (message, file) => {
      setInnerLoading(true);
      const data = file
        ? await seller.messageUploadFile({rfpId: request.id, file})
        : null;
      seller
        .createRfpMessage({rfpId: request.id, fileId: data?.id ?? data, message})
        .then(() => {
          loadMessages();
          showSuccess(t("SUCCESSFULLY_SENT_MESSAGE"));
        })
        .catch((error) => {
          clientStore.sendError(error, {
            rfp: request,
            rfpId: request.id,
            fileId: data?.id ?? data,
          });
          throw error;
        })
        .finally(() => setInnerLoading(false));
    };

    useEffect(() => {
      loadMessages();
    }, [loadMessages]);

    return (
      <div className="message-card__messages-wrapper">
        <MessagePanel
          mode="create"
          message={{
            User: {
              firstName: authUser.firstName,
              lastName: authUser.lastName,
            },
          }}
          placeholder={`${t("NEW_MESSAGE_PLACEHOLDER")} ${request?.Request?.Buyer?.name}`}
          addClass="mb-4"
          sendMessage={clickSend}
          permissionsMessage={["CREATE_SELLER_RFT_MESSAGES_MESSAGE"]}
          permissionsFile={["CREATE_SELLER_RFT_MESSAGES_FILE"]}
          loading={innerLoading}
        />
        {loading && <Preloader addClass="preloader100" />}
        {!loading && (
          <div className="message-card__messages-list">
            {!!messages?.length &&
              messages.map((message, index) => (
                <MessagePanel
                  key={message.id}
                  message={message}
                  addClass={index < messages.length - 1 ? "mb-4" : "mb-0"}
                  isMyMessage={authUser.id !== message.UserId}
                />
              ))}
          </div>
        )}
      </div>
    );
  })
);

export default MessagesSection;
