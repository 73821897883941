import React from "react";
import {useTranslation} from "react-i18next";
//local
import {
  IconMailLetter,
  IconHandOnRight,
  IconLink,
  IconCheck,
  IconTrumbsUp,
  IconTrumbsDown,
  IconNotificationCircle,
} from "components/icons";
import {InitialsBadge} from "components/InitialsBadge";
import {ButtonTransparent} from "components/buttons/ButtonTransparent";
import EmailRecipientsBouncedStatus from "pages/OurRequests/components/EmailRecipientsBouncedStatus";
import {CustomTooltip} from "components/CustomTooltip";
import {dateFormat} from "helpers/datetime";
import {LockValue} from "components/LockValue";
import {hasPermission} from "helpers/helper";
import {arrayToCommaAnd} from "helpers/helper";
import {color} from "constants/color.consts";
const OK_ICON = 1;
const NO_ICON = 4;

const RecipientItem = ({
  recipient,
  registered,
  onClickLinkButton,
  invitedRecipient,
  remindRecipient,
  reminderDays,
}) => {
  const readIcon = recipient.readed ? OK_ICON : NO_ICON;
  const interestedIcon = recipient.interested ? <IconTrumbsUp /> : <IconTrumbsDown />;
  const offerIcon = recipient.offered ? OK_ICON : NO_ICON;
  const UserAssignee = recipient.UserAssignee;
  const ReminderHistory = recipient.ReminderHistory;
  const ReminderHistoryLength = recipient.ReminderHistory?.length;
  const {t} = useTranslation();

  const reminderHistory = ReminderHistoryLength
    ? ReminderHistory.map((item, i) => `${dateFormat(item.createdAt)}\n`).join("")
    : "";
  const remindersLogText = `${t(
    "RECIPIENTS_REGISTERED_REMINDER_LOG_DESCRIPTION"
  )}\n${reminderHistory}`;
  const reminderDaysText = reminderDays?.length
    ? arrayToCommaAnd(reminderDays, t("AND"))
    : "";

  return (
    <div className="requests-card_body nohover">
      <div className="requests-card_body__icon __wrapper">
        {registered ? <IconHandOnRight /> : <IconMailLetter width="21" height="13" />}
      </div>
      <div
        className={`requests-card_body__recipient-name${
          registered ? "" : " __unregistered"
        } __wrapper`}
      >
        <span className={!registered && recipient.isBounced ? " __strikethrough" : ""}>
          {recipient.name ?? recipient.email}
        </span>
        {!registered && recipient.isBounced && <EmailRecipientsBouncedStatus />}
      </div>
      {registered && (
        <div className="requests-card_body__recipient-reminders-column">
          <span>{`${t(
            "RECIPIENTS_REGISTERED_REMINDER_DESCRIPTION"
          )} ${ReminderHistoryLength}`}</span>
          {!!ReminderHistoryLength && (
            <CustomTooltip text={remindersLogText} centerText noTranslate>
              <div style={{height: "32px", width: "fit-content"}}>
                <IconNotificationCircle color={color.black} addClass="mt-1 ml-2" />
              </div>
            </CustomTooltip>
          )}
          {!(recipient.interested === false || recipient.offered) && (
            <LockValue
              canPermissions={["ENABLE_RFT_RECIPIENTS_REMINDER"]}
              needLabel
              needShowModalToSubscribe
            >
              <ButtonTransparent
                addClass="ml-4"
                small
                nowrap
                hoverable
                name={"RECIPIENTS_REGISTERED_REMINDER_BUTTON"}
                onClick={() =>
                  hasPermission(["ENABLE_RFT_RECIPIENTS_REMINDER"]) && remindRecipient()
                }
              />
            </LockValue>
          )}
        </div>
      )}
      {!registered && (
        <div className="requests-card_body__recipient-invite-button">
          <span className="pr-2 __italic">
            {t("RECIPIENTS_NOT_REGISTERED_REMINDER_DESCRIPTION")}
          </span>
          <CustomTooltip
            text={`${t(
              "RECIPIENTS_NOT_REGISTERED_REMINDER_TOOLTIP_01"
            )} ${reminderDaysText} ${t("RECIPIENTS_NOT_REGISTERED_REMINDER_TOOLTIP_02")}`}
            noTranslate
            centerText
          >
            <div style={{height: "32px", width: "fit-content"}}>
              <IconNotificationCircle color={color.black} addClass="mt-1" />
            </div>
          </CustomTooltip>
          <LockValue
            canPermissions={["ENABLE_RFT_RECIPIENTS_INVITE_LINK"]}
            needLabel
            justHide
          >
            {invitedRecipient?.email === recipient.email ? (
              <CustomTooltip
                text={`${t("COPY_INVITE_LINK_BUTTON_TOOLTIP_03")} \n ${t(
                  "COPY_INVITE_LINK_BUTTON_TOOLTIP_04"
                )} ${recipient.email}`}
                color="green"
                placement="right"
                visible
                centerText
                addClass="recipient-invite-button-tooltip"
              >
                <div className="pa-0 df">
                  <ButtonTransparent
                    addClass="ml-4"
                    small
                    nowrap
                    color="green"
                    name={"LINK_COPIED"}
                    icon={<IconCheck color={color.green} />}
                  />
                </div>
              </CustomTooltip>
            ) : (
              <CustomTooltip
                text={`${t("COPY_INVITE_LINK_BUTTON_TOOLTIP_01")} \n ${t(
                  "COPY_INVITE_LINK_BUTTON_TOOLTIP_02"
                )}`}
                addClass="recipient-invite-button-tooltip"
              >
                <div className="pa-0 df">
                  <ButtonTransparent
                    addClass="ml-4"
                    small
                    hoverable
                    nowrap
                    name={"COPY_INVITE_LINK_BUTTON"}
                    icon={<IconLink color={color.link} />}
                    onClick={() =>
                      hasPermission(["ENABLE_RFT_RECIPIENTS_INVITE_LINK"]) &&
                      onClickLinkButton()
                    }
                  />
                </div>
              </CustomTooltip>
            )}
          </LockValue>
        </div>
      )}
      {registered && (
        <React.Fragment>
          <div className="organization-card_body__indicator __sub-status">
            <span className={`indicator_sub-status __${readIcon}`} />
          </div>
          <div className="organization-card_body__indicator __sub-status">
            {recipient.interested !== null && (
              <CustomTooltip text={recipient.interested ? "" : recipient.rejectMessage}>
                <span>{interestedIcon}</span>
              </CustomTooltip>
            )}
          </div>
          <div className="organization-card_body__indicator __sub-status">
            {UserAssignee && (
              <CustomTooltip
                text={`${UserAssignee.firstName} ${UserAssignee.lastName}\n${UserAssignee.mobilePhone}\n${UserAssignee.email}`}
                addClass="organization-card_body__indicator__user-tooltip"
              >
                <span>
                  <InitialsBadge
                    firstName={UserAssignee.firstName}
                    lastName={UserAssignee.lastName}
                  />
                </span>
              </CustomTooltip>
            )}
          </div>
          <div className="organization-card_body__indicator __sub-status">
            <span className={`indicator_sub-status __${offerIcon}`} />
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

export default RecipientItem;
