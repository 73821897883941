import React from "react";
import {
  IconHandOnDown,
  IconHandOnUp,
  IconHandOnRight,
  IconHandOnLeft,
} from "components/icons";
import {useTranslation} from "react-i18next";

const HandyPointerBlob = ({
  showNewBadge, // Show or hide red "new" badge
  showNewsBadge, // Show or hide red "news" badge
  showPointer, // Show or hide pointy hand icon
  pointerAfterText, // Show the pointer after the text instead of (default) before
  pointerBeforeBadge, // Show the pointer before the NEW badge
  noAnimation, // Include to stop wiggly animation
  pointyAnimation, //Include to change to up/down movement
  pointerDirection = "down", // ['down', 'up', 'left', 'right']? Default is down
  text, // Translation key for text
  forceAlign, //Force flex alignment right or left. Default is center.
  forcePosition, // "absolute" or "relative" to force positioning
  topPos, // Pixel value for top positioning
  rightPos, // Pixel value for right positioning
  bottomPos, // Pixel value for bottom positioning
  leftPos, // Pixel value for left positioning
  fontSize, // allow override of font size
  addClass, // wrapper class
}) => {
  const {t} = useTranslation();

  const styleValues = {
    "--top": topPos ? `${topPos}px` : undefined,
    "--right": rightPos ? `${rightPos}px` : undefined,
    "--bottom": bottomPos ? `${bottomPos}px` : undefined,
    "--left": leftPos ? `${leftPos}px` : undefined,
    fontSize: fontSize || "initial",
  };

  const forceAlignClass =
    forceAlign === "right" ? "align-right" : forceAlign === "left" ? "align-left" : "";
  const animationClass = pointyAnimation
    ? "pointy-animation"
    : noAnimation
    ? "no-animation"
    : "wiggly-animation";
  const iconHandClass = `${animationClass} ${
    pointerAfterText ? " show-last" : pointerBeforeBadge ? "show-first" : ""
  }`;

  return (
    <div
      className={`handy-pointer-blob ${forcePosition || ""} ${forceAlignClass || ""} ${
        addClass || ""
      }`}
      style={styleValues}
    >
      {showNewBadge && <div className="newbadge">{t("NEW_FEATURE_NEW")}</div>}
      {showNewsBadge && <div className="newsbadge">{t("NEW_FEATURE_NEWS")}</div>}
      {showPointer && (
        <React.Fragment>
          {pointerDirection === "down" && <IconHandOnDown addClass={iconHandClass} />}
          {pointerDirection === "up" && <IconHandOnUp addClass={iconHandClass} />}
          {pointerDirection === "left" && <IconHandOnLeft addClass={iconHandClass} />}
          {pointerDirection === "right" && <IconHandOnRight addClass={iconHandClass} />}
        </React.Fragment>
      )}

      <div className="scribble">{t(text)}</div>
    </div>
  );
};

export default HandyPointerBlob;
