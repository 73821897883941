import React from "react";
//local
import {formatCompanyNumber} from "helpers/helper";
import {Tag} from "components/Tag";
import {color} from "constants/color.consts";
import {ButtonTransparent} from "components/buttons/ButtonTransparent";
import CreditCompanyUpsaleImage from "assets/images/svg/credit-company-upsale-image.svg";
import cn from "classnames";
import {useTranslation} from "react-i18next";

const CreditReportCompaniesUpsaleComponent = ({companyNumber, addClass}) => {
  const {t} = useTranslation();
  return (
    <div
      className={cn("credit-report-companies-upsale-component", {[addClass]: !!addClass})}
    >
      <img src={CreditCompanyUpsaleImage} alt="" />
      <div className="credit-report-companies-upsale-component__description">
        <h3 className="credit-report-companies-upsale-component__title">
          {`${t(
            "FIND_SUPPLIERS_CREDIT_REPORT_COMPANIES_UPSALE_TITLE"
          )} ${formatCompanyNumber(companyNumber)} ?`}
          <Tag
            text="NEW_FEATURE_NEWS"
            needTranslation
            color={color.red}
            uppercase
            tiny
            noBackground
            addClass="ml-4"
          />
        </h3>

        <p>{t("FIND_SUPPLIERS_CREDIT_REPORT_COMPANIES_UPSALE_TEXT_1")}</p>
        <ButtonTransparent
          name="MONITORING_TAB_INACTIVE_CTA"
          large
          nonTransparent
          onClick={() => window.open(t("URL_TO_PUBLIC_CONTACT_PAGE"), "_blank")}
          color="purple"
          addClass="credit-report-companies-upsale-component__button"
        />
      </div>
    </div>
  );
};

export default CreditReportCompaniesUpsaleComponent;
