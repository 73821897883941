import React, {useState, useEffect, useMemo} from "react";
import {inject, observer} from "mobx-react";
import {useNavigate} from "react-router-dom";
// local
import {OrganizationCard} from "components/organizationCard/OrganizationCard";
import {PaginationType} from "components/Pagination";
import {Preloader} from "components/Preloader";
import HandyPointerBlob from "components/HandyPointerBlob";
import OrganizationCardHead from "components/organizationCard/OrganizationCardHead";
import TableTitleResult from "components/Table/TableTitleResult";
import EmptyData from "components/EmptyData";
import SubscriptionUpgrageLocalModal from "components/modals/SubscriptionUpgrageLocalModal";
import CreditReportCompanyWidget from "./CreditReportCompanyWidget";
import CreditReportCompaniesUpsaleComponent from "./CreditReportCompaniesUpsaleComponent";
import {hasPermission, getUrlParams} from "helpers/helper";

import cn from "classnames";

const Organizations = inject("store")(
  observer(({store: {auth, organization}, onChangeSort}) => {
    const {
      filterParams,
      suppliers: {
        totalItems,
        currentPage,
        searchPreferences,
        isValidCompanyNumber,
        isCompanyExists,
      },
      loading,
    } = organization;
    const {
      user: {permissions},
      activeOrganization,
    } = auth;
    const navigate = useNavigate();
    const [isHistory, setShowHistory] = useState(false);
    const isResults = !!searchPreferences?.length;
    const isCreditReportCompaniesFeature =
      activeOrganization?.enableCreditReportCompanies;
    const canGetOrganizationSnapshots = useMemo(
      () =>
        isCreditReportCompaniesFeature &&
        isValidCompanyNumber &&
        !isCompanyExists &&
        hasPermission(["ENABLE_COMPANY_CREDITSAFE_SNAPSHOTS"]),
      [isCreditReportCompaniesFeature, isValidCompanyNumber, isCompanyExists]
    );
    const SubscriptionUpgrageLocalModalPermissions = [
      "READ_TABLE_CREDIT_RATING",
      "READ_TABLE_TURNOVER",
      "READ_TABLE_COLLECTIVE_UNION_AGREEMENTS",
      "READ_TABLE_INSURANCE",
      "READ_TABLE_TAX_VAT_EMPLOYERS",
    ];

    const [snapshots, setSnapshots] = useState([]);

    const updateUrlParams = (search) => navigate({search});

    const handlePageChange = (pageNumber) => {
      organization.setFilterParams({...filterParams, page: pageNumber});
      updateUrlParams(getUrlParams(filterParams, {page: pageNumber}));
      organization.searchSuppliers();
    };

    useEffect(() => {
      !isResults &&
        canGetOrganizationSnapshots &&
        filterParams?.name &&
        organization.getOrganizationSnapshots(filterParams?.name).then(setSnapshots);
    }, [isResults, canGetOrganizationSnapshots, organization, filterParams?.name]);

    return (
      <div className={cn("organizations", {__results: isResults})}>
        {loading && <Preloader addClass="preloader250" />}
        {!loading && searchPreferences && (
          <React.Fragment>
            <TableTitleResult title={"RESULTS"} totalItems={!isResults && 0} />

            {isResults && (
              <HandyPointerBlob
                showNewsBadge
                showPointer
                text="NEW_FEATURE_SEARCHFILTERS_TEXT"
                forcePosition="absolute"
                topPos={35}
                rightPos={29}
              />
            )}

            <div className="organizations__suppliers">
              {isResults && (
                <OrganizationCardHead
                  onChangeSort={onChangeSort}
                  isHistory={isHistory}
                  onChangeShowHistory={() => setShowHistory(!isHistory)}
                />
              )}
              {isResults &&
                searchPreferences.map((organization) => (
                  <OrganizationCard
                    key={organization.id}
                    organization={organization.Organization}
                    isHistory={isHistory}
                    showTitleIcon
                    place="common"
                  />
                ))}
              {!isResults && (
                <React.Fragment>
                  <EmptyData text="FIND_SUPPLIERS_EMPTY_RESULTS" />
                  {isValidCompanyNumber && !isCompanyExists && (
                    <React.Fragment>
                      {isCreditReportCompaniesFeature ? (
                        <CreditReportCompanyWidget
                          companyNumber={filterParams?.name}
                          ownOrganization={activeOrganization}
                          snapshots={snapshots}
                          addClass="mt-8"
                        />
                      ) : (
                        <CreditReportCompaniesUpsaleComponent
                          companyNumber={filterParams?.name}
                        />
                      )}
                    </React.Fragment>
                  )}
                </React.Fragment>
              )}
              {!hasPermission(SubscriptionUpgrageLocalModalPermissions, permissions) &&
                !isCreditReportCompaniesFeature &&
                isResults &&
                !isValidCompanyNumber && (
                  <SubscriptionUpgrageLocalModal
                    canPermissions={SubscriptionUpgrageLocalModalPermissions}
                  />
                )}
            </div>
            {totalItems > 20 && (
              <PaginationType
                totalItems={totalItems}
                currentPage={currentPage}
                countPerPage={20}
                onPageChange={handlePageChange}
              />
            )}
          </React.Fragment>
        )}
      </div>
    );
  })
);

export default Organizations;
