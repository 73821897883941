import React, {useState, useMemo} from "react";
import {Link, useNavigate} from "react-router-dom";
import {inject, observer} from "mobx-react";
import {useTranslation} from "react-i18next";
import {debounce} from "lodash";
//local
import {toRequestId, toContractId} from "constants/routes.const";
import {
  IconEdit,
  IconDelete,
  IconPlus,
  IconBullHorn,
  IconChevronRight,
  IconChevronDown,
  IconPointerUp,
} from "components/icons";
import {ConfirmModal} from "components/ConfirmModal";
import CustomCheckbox from "components/CustomCheckbox";
import {showSuccess} from "helpers/notifications.helper";
import {ButtonTransparent} from "components/buttons/ButtonTransparent";
import {DropdownEllipsis} from "components/DropdownEllipsis";
import {InitialsBadge} from "components/InitialsBadge";
import ProcurementStatusesSelect from "components/selects/ProcurementStatusesSelect";
import ProcurementDetails from "./ProcurementDetails";
import ProcurementDetailsNotRegistered from "./ProcurementDetailsNotRegistered";
import {ProcurementTimelineDateModal} from "components/modals/ProcurementTimelineDateModal";
import {Input} from "components/Input";
import {LockValue} from "components/LockValue";
import {hasPermission} from "helpers/helper";
import {Preloader} from "components/Preloader";
import {dateFormat} from "helpers/datetime";
import {procurementStatuses, rfpStatuses} from "constants/buyer.consts";
import {formattedNumber} from "helpers/number.formats";
import {getWeek} from "helpers/datetime";
import {oneWeek} from "constants/user.consts";
import {color} from "constants/color.consts";
import cn from "classnames";

const ProcurementItem = inject("store")(
  observer(
    ({
      store: {buyer, organization},
      procurement,
      loading,
      currentUserId,
      statuses,
      activeTab,
      editProcurement,
      deleteProcurement,
      createRFP,
      projectId,
      expand,
      checkbox,
      expandLoading,
      selected,
      datesSteps,
      clickExpand,
      clickOnSelect,
      changeInterestedProposalStatus,
      updateProject,
    }) => {
      const {favoritesOrganization, blacklistOrganization} = organization;
      const {t} = useTranslation();
      const navigate = useNavigate();

      const [procurementStatus, setProcurementStatus] = useState(procurement.status);
      const [timelineDateKeyModal, setTimelineDateKeyModal] = useState(null);

      const tabClass = {
        0: "__plan",
        1: "__budget",
        2: "__dates",
      };

      const rfp = useMemo(() => procurement.rfp, [procurement]);
      const isRFP = useMemo(() => !!rfp, [rfp]);
      const unpunblishedRft = useMemo(() => rfp?.status !== rfpStatuses.PUBLISHED, [rfp]);
      const draftRft = useMemo(() => rfp?.status === rfpStatuses.DRAFT, [rfp]);
      const canClickEditDraft = useMemo(
        () => hasPermission(["EDIT_RFT"]) || !draftRft,
        [draftRft]
      );
      const canClickUpublished = useMemo(
        () => hasPermission(["SHOW_UNPUBLISHED_RFT"]) || !unpunblishedRft,
        [unpunblishedRft]
      );

      const noDates = useMemo(
        () => !datesSteps.filter((date) => !!date.value).length,
        [datesSteps]
      );

      const isContract = useMemo(
        () => isRFP && !!procurement.Contract,
        [isRFP, procurement.Contract]
      );
      const contractSellerOrganizationId = useMemo(
        () => (isContract ? procurement.Contract?.SellerOrganization?.id : null),
        [isContract, procurement.Contract]
      );
      const isRFPClosedAt = useMemo(
        () =>
          activeTab !== 2 &&
          isRFP &&
          [procurementStatuses.RFP_OUT, procurementStatuses.OFFERS_IN].includes(
            procurementStatus
          ),
        [isRFP, procurementStatus, activeTab]
      );
      const buyerSignatories = useMemo(
        () => procurement.Contract?.BuyerSignatories,
        [procurement.Contract?.BuyerSignatories]
      );
      const sellerSignatories = useMemo(
        () => procurement.Contract?.SellerSignatories,
        [procurement.Contract?.SellerSignatories]
      );

      const isSignedContract = useMemo(
        () =>
          isContract &&
          !!buyerSignatories?.length &&
          !!sellerSignatories?.length &&
          buyerSignatories?.every((buyer) => !!buyer.BuyerSignId) &&
          sellerSignatories?.every((seller) => !!seller.SellerSignId),
        [isContract, buyerSignatories, sellerSignatories]
      );
      const unreadRegisteredRecipients = useMemo(
        () =>
          rfp?.recipients?.registered?.filter(
            (recipient) =>
              (!!recipient?.Offer && !recipient.Offer.readed) ||
              !!recipient?.MessagesStat?.unreadMessageCount
          ),
        [rfp?.recipients?.registered]
      );
      const registeredRecipients = useMemo(
        () => (expand ? rfp?.recipients?.registered : unreadRegisteredRecipients),
        [expand, rfp?.recipients?.registered, unreadRegisteredRecipients]
      );
      const unreadPublicRecipients = useMemo(
        () =>
          rfp?.recipients?.public?.filter(
            (recipient) =>
              (!!recipient?.Offer && !recipient.Offer.readed) ||
              !!recipient?.MessagesStat?.unreadMessageCount
          ),
        [rfp?.recipients?.public]
      );
      const publicRecipients = useMemo(
        () => (expand ? rfp?.recipients?.public : unreadPublicRecipients),
        [expand, rfp?.recipients?.public, unreadPublicRecipients]
      );

      const countAllRecipients = useMemo(
        () =>
          [
            ...(rfp?.recipients?.registered ?? []),
            ...(rfp?.recipients?.public ?? []),
            ...(rfp?.ManualOffers ?? []),
            ...(rfp?.recipients?.notRegistered ?? []),
          ].length,
        [rfp]
      );
      const isEquallyCountRecipients = useMemo(
        () =>
          countAllRecipients ===
          [...(unreadRegisteredRecipients ?? []), ...(unreadPublicRecipients ?? [])]
            .length,
        [unreadRegisteredRecipients, unreadPublicRecipients, countAllRecipients]
      );

      const isExpand = useMemo(
        () => !isEquallyCountRecipients && !!countAllRecipients,
        [isEquallyCountRecipients, countAllRecipients]
      );
      const isGrayIcon = useMemo(
        () => !isRFP || (expand && !isExpand),
        [isRFP, expand, isExpand]
      );

      const isEditBudget = hasPermission(["EDIT_PROCUREMENTS_BUDGET_TAB"]);
      const LockBudget = ({children}) => (
        <LockValue
          canPermissions={["EDIT_PROCUREMENTS_BUDGET_TAB"]}
          needLabel
          needShowModalToSubscribe
        >
          {children}
        </LockValue>
      );

      const isEditDatePlan = hasPermission(["EDIT_PROCUREMENTS_TIME_TAB"]);

      const getPreviousDate = (date, weekCount) => new Date(date - oneWeek * weekCount);

      // getting the color of a specific tile from six tiles in a row on the Dates tab
      const getTileColor = (date, index) => {
        const currentWeek = getWeek(Date.now());
        const acceptableStatuses = [
          [procurementStatuses.SELECT_STATUS],
          [procurementStatuses.SELECT_STATUS, procurementStatuses.RFP_DRAFT],
          [
            procurementStatuses.SELECT_STATUS,
            procurementStatuses.RFP_DRAFT,
            procurementStatuses.RFP_OUT,
          ],
          [
            procurementStatuses.SELECT_STATUS,
            procurementStatuses.RFP_DRAFT,
            procurementStatuses.RFP_OUT,
            procurementStatuses.CONTRACT_DRAFT,
            procurementStatuses.CONTRACT,
          ],
        ];
        if (noDates && index === 5) return color.lightYellow;
        if (!date || currentWeek < getWeek(date) || [4, 5].includes(index))
          return color.offWhite;
        if (acceptableStatuses[index].includes(procurementStatus)) return color.pink;
        else return color.whiteGreen;
      };

      const confirmDelete = () => {
        ConfirmModal({
          title: t("WANT_DELETE_PROCUREMENT"),
          text: t("CANT_RETURN"),
          type: "warning",
          onOk: () => deleteProcurement(procurement.id),
        });
      };

      const procurementMenu = [
        {
          label: "EDIT",
          permissions: ["EDIT_PROCUREMENT"],
          onClick: () => editProcurement(procurement.id),
          icon: <IconEdit />,
        },
        {
          label: "DELETE",
          onClick: confirmDelete,
          icon: <IconDelete />,
          hide: isRFP,
        },
      ];

      const checkStatus = (status) =>
        status === procurementStatuses.STOPPED
          ? ConfirmModal({
              title: t("WANT_CHANGE_RFT_STATUS"),
              text: t("CANT_CHANGE_RFT_STATUS"),
              type: "warning",
              onOk: () => updateStatus(status),
            })
          : updateStatus(status);

      const updateStatus = (status) => {
        setProcurementStatus(status);
        buyer
          .updateProcurement(projectId, procurement.id, {status})
          .then(() => showSuccess(t("SHOW_UPDATED_STATUS")));
      };

      const updateBudget = debounce((costBudget) => {
        buyer
          .updateProcurement(projectId, procurement.id, {costBudget})
          .then(() => updateProject());
      }, 800);

      const updateSpent = debounce((costSpent) => {
        buyer
          .updateProcurement(projectId, procurement.id, {costSpent})
          .then(() => updateProject());
      }, 800);

      const updateTimelineDate = (values) => {
        // implement auto-set routine if user choose timelineDeliveryDate into empty row
        const isNeddAutoSetRoutine = noDates && !!values.timelineDeliveryDate;
        const dates = isNeddAutoSetRoutine
          ? {
              ...values,
              timelineCreateRftDate: getPreviousDate(values.timelineDeliveryDate, 20),
              timelinePublishRftDate: getPreviousDate(values.timelineDeliveryDate, 18),
              timelineReceiveTendersDate: getPreviousDate(
                values.timelineDeliveryDate,
                14
              ),
              timelineContractReadyDate: getPreviousDate(values.timelineDeliveryDate, 12),
              timelinePlaceOrderDate: getPreviousDate(values.timelineDeliveryDate, 8),
            }
          : values;

        buyer.updateProcurement(projectId, procurement.id, dates).then(() => {
          updateProject();
          setTimelineDateKeyModal(null);
          isNeddAutoSetRoutine &&
            showSuccess(
              t("PROCUREMENT_TIMELINE_AUTO_SET_ROW_SUCCESS_TEXT"),
              10,
              t("PROCUREMENT_TIMELINE_AUTO_SET_ROW_SUCCESS_TITLE")
            );
        });
      };

      const handleProposalClick = (offerId) =>
        navigate(toRequestId(rfp?.id), {state: {offerId}});

      return (
        <div
          id={procurement.rfp?.id}
          className={`project-view-procurements-table_item ${tabClass[activeTab]}`}
        >
          <div className="project-view-procurements-table_item__header">
            <div
              className={`project-view-procurements-table_item__info ${tabClass[activeTab]}`}
            >
              <div className="project-view-procurements-table_item__name-status">
                <div className="project-view-procurements-table_item__name">
                  {checkbox && (
                    <CustomCheckbox
                      checked={selected}
                      onChange={clickOnSelect}
                      addClass="mr-4"
                    />
                  )}
                  {activeTab === 0 && (
                    <div
                      onClick={() =>
                        isGrayIcon ? undefined : clickExpand(procurement.id)
                      }
                      className={cn(
                        "project-view-procurements-table_item__name__expand-button",
                        {__disabled: isGrayIcon}
                      )}
                    >
                      {expandLoading ? (
                        <Preloader size="default" addClass="mt-0" />
                      ) : expand && isExpand ? (
                        <IconChevronDown />
                      ) : (
                        <IconChevronRight />
                      )}
                    </div>
                  )}
                  <InitialsBadge
                    firstName={procurement.Assignee?.firstName}
                    lastName={procurement.Assignee?.lastName}
                    isCurrentUser={procurement.Assignee?.id === currentUserId}
                    addClass="mr-2"
                  />
                  <LockValue
                    canPermissions={["SHOW_UNPUBLISHED_RFT", "EDIT_RFT"]}
                    needAllPermissions
                    additionalShowCondition={!(isRFP && unpunblishedRft)}
                    needLabel
                    noLockIcon
                    needShowModalToSubscribe
                  >
                    {isRFP && canClickUpublished ? (
                      <Link
                        to={
                          canClickUpublished && canClickEditDraft && toRequestId(rfp?.id)
                        }
                      >
                        {rfp.name}
                      </Link>
                    ) : (
                      <span className={canClickUpublished ? "" : "cpointer"}>
                        {procurement.name}
                      </span>
                    )}
                  </LockValue>

                  {isRFP && rfp.isPublic && <IconBullHorn addClass="ml-2" />}
                  <DropdownEllipsis list={procurementMenu} tightMenu addClass="ml-2" />
                  {!isRFP && (
                    <LockValue canPermissions={["CREATE_RFT"]} needLabel justHide>
                      <ButtonTransparent
                        name="CREATE_RFP_BUTTON"
                        icon={<IconPlus />}
                        tiny
                        onClick={() =>
                          hasPermission(["CREATE_RFT"]) && createRFP(procurement)
                        }
                      />
                    </LockValue>
                  )}
                  {loading && <Preloader size="medium" addClass="mla" />}
                </div>
                <div className="project-view-procurements-table_item__status">
                  {isRFPClosedAt && (
                    <div className="project-view-procurements-table_item__closed-date">
                      {t("CLOSES_FOR_OFFERS")}
                      <span>{dateFormat(rfp.closedAt)}</span>
                    </div>
                  )}
                  <LockValue
                    canPermissions={["EDIT_PROCUREMENT_STATUS"]}
                    needLabel
                    noLockIcon
                    needShowModalToSubscribe
                  >
                    <ProcurementStatusesSelect
                      list={statuses}
                      disabled={!hasPermission(["EDIT_PROCUREMENT_STATUS"])}
                      onChange={(status) =>
                        hasPermission(["EDIT_PROCUREMENT_STATUS"]) && checkStatus(status)
                      }
                      defaultValue={procurementStatus}
                      selectedValue={procurementStatus}
                    />
                  </LockValue>
                </div>
              </div>
            </div>

            {activeTab === 0 && (
              <div className="project-view-procurements-table_head__details"></div>
            )}
            {activeTab === 1 && (
              <React.Fragment>
                <div className="project-view-procurements-table_item__pre-cost"></div>
                <div className="project-view-procurements-table_item__cost">
                  <LockBudget>
                    <Input
                      name="procurement-budget"
                      flatView
                      boldText
                      width={isEditBudget ? "112px" : "91px"}
                      alignRight
                      fontSize={12}
                      placeholder="0"
                      realNumbers
                      digitsDivider
                      customHeight={30}
                      defaultValue={procurement.costBudget}
                      readOnly={!isEditBudget}
                      onChange={(value) => isEditBudget && updateBudget(value)}
                    />
                  </LockBudget>
                </div>
                <div className="project-view-procurements-table_item__cost">
                  <LockBudget>
                    <Input
                      name="procurement-spent"
                      flatView
                      boldText
                      width={isEditBudget ? "112px" : "91px"}
                      alignRight
                      fontSize={12}
                      placeholder="0"
                      realNumbers
                      digitsDivider
                      customHeight={30}
                      defaultValue={procurement.costSpent}
                      readOnly={!isEditBudget}
                      onChange={(value) => isEditBudget && updateSpent(value)}
                    />
                  </LockBudget>
                </div>
                <div
                  className={cn("project-view-procurements-table_item__cost pr-2", {
                    __red: +procurement.costResult < 0,
                  })}
                >
                  {formattedNumber(procurement.costResult)}
                </div>
              </React.Fragment>
            )}
            {activeTab === 2 && (
              <div className="project-view-procurements-table_head__dates">
                {datesSteps
                  .map((date, index) => ({
                    ...date,
                    color: getTileColor(procurement[date.key], index),
                  }))
                  .map((date) => (
                    <LockValue
                      canPermissions={["EDIT_PROCUREMENTS_TIME_TAB"]}
                      needLabel
                      needShowModalToSubscribe
                      addClassWrapper="lock-value"
                    >
                      <div
                        key={date.key}
                        className="project-view-procurements-table_head__dates__step"
                        style={{
                          backgroundColor: date.color ?? color.lightGray,
                          cursor: "pointer",
                        }}
                        onClick={() => isEditDatePlan && setTimelineDateKeyModal(date)}
                      >
                        {date.value ? (
                          <React.Fragment>
                            <span
                              className="__normal"
                              style={{opacity: "0.2", marginRight: "3px"}}
                            >
                              {t("WEEK_ABBREVIATED")}
                            </span>
                            {getWeek(date.value)}
                            {new Date(date.value).getYear() !== new Date().getYear() && (
                              <span
                                className="__normal fz-12"
                                style={{
                                  marginLeft: "2px",
                                  marginTop: "2px",
                                  opacity: "0.2",
                                }}
                              >
                                {new Date(date.value).getYear() - 100}
                              </span>
                            )}
                          </React.Fragment>
                        ) : (
                          <IconPointerUp color={color.link} />
                        )}
                      </div>
                    </LockValue>
                  ))}
              </div>
            )}
          </div>

          {activeTab === 0 && (
            <div className="project-view-procurements-table_item__details">
              {registeredRecipients?.map((recipient) => (
                <ProcurementDetails
                  key={recipient.id}
                  recipient={recipient}
                  rfpId={rfp.id}
                  isFavorite={favoritesOrganization.some(
                    (item) => item.id === recipient.Organization.id
                  )}
                  isBlacklisted={blacklistOrganization.some(
                    (item) => item.id === recipient.Organization.id
                  )}
                  contractSellerOrganizationId={contractSellerOrganizationId}
                  isContract={isContract}
                  isSignedContract={isSignedContract}
                  onClickProposal={() =>
                    navigate(toRequestId(rfp?.id), {
                      state: {
                        from: "offers",
                        offerId: recipient.Offer?.id,
                      },
                    })
                  }
                  onClickContract={() => !!rfp?.id && navigate(toContractId(rfp?.id))}
                  onUpdateProposal={changeInterestedProposalStatus}
                />
              ))}
              {publicRecipients?.map((recipient) => (
                <ProcurementDetails
                  key={recipient.id}
                  recipient={recipient}
                  rfpId={rfp.id}
                  isFavorite={favoritesOrganization.some(
                    (item) => item.id === recipient.Organization.id
                  )}
                  isBlacklisted={blacklistOrganization.some(
                    (item) => item.id === recipient.Organization.id
                  )}
                  isPublic
                  onClickProposal={() => handleProposalClick(recipient.Offer?.id)}
                  onUpdateProposal={changeInterestedProposalStatus}
                />
              ))}
              {expand &&
                rfp?.ManualOffers?.map((recipient) => (
                  <ProcurementDetailsNotRegistered
                    key={recipient.id}
                    recipient={recipient.organizationName}
                    description="RECIPIENTS_NOT_REGISTERED_MANUAL_ATTENTION"
                  />
                ))}
              {expand &&
                rfp?.recipients?.notRegistered?.map((recipient) => (
                  <ProcurementDetailsNotRegistered
                    key={recipient.id}
                    recipient={recipient}
                    reminderDays={rfp.reminderDays}
                    description="RECIPIENTS_NOT_REGISTERED_REMINDER_ATTENTION"
                  />
                ))}
            </div>
          )}
          {timelineDateKeyModal && (
            <ProcurementTimelineDateModal
              data={timelineDateKeyModal}
              datesSteps={datesSteps}
              procurement={procurement}
              titleName={rfp?.name ?? procurement.name}
              onSave={updateTimelineDate}
              onClose={() => setTimelineDateKeyModal(null)}
            />
          )}
        </div>
      );
    }
  )
);

export default ProcurementItem;
