import React, {useState, useMemo, useRef} from "react";
import {useTranslation} from "react-i18next";
// local
import {Modal} from "components/Modal";
import {ButtonTransparent} from "components/buttons/ButtonTransparent";
import {AddableFormikField} from "components/AddableFormikField";
import {IconUsers, IconMailLetter} from "components/icons/index";
import {InitialsBadge} from "components/InitialsBadge";
import CustomRadioSwitch from "components/CustomRadioSwitch";
import SelectionRow from "components/selects/SelectionRow";
import {color} from "constants/color.consts";

export const ModalRecipientMembers = ({
  organization,
  onClose,
  currentUser,
  onAddEmailRecipients,
  onChoiceNotificationMember,
  emailLoading,
}) => {
  const {t} = useTranslation();
  const [membersToReceive, setMembersToReceive] = useState(organization.MembersToReceive);
  const isLastMember = useMemo(() => membersToReceive?.length < 2, [membersToReceive]);
  const scrollRef = useRef(null);

  const saveScrollPosition = () => (scrollRef.current ? scrollRef.current.scrollTop : 0);
  const restoreScrollPosition = (position) =>
    scrollRef.current && Object.assign(scrollRef.current, {scrollTop: position});

  const handleToggle = (itemId, checked) => {
    const scrollPosition = saveScrollPosition();

    setMembersToReceive((prevState) =>
      checked
        ? [...prevState, {id: itemId}]
        : prevState.filter((prevMember) => prevMember.id !== itemId)
    );

    setTimeout(() => restoreScrollPosition(scrollPosition), 0);
  };

  const MembersList = ({members}) => (
    <div className="recipients-tab__members-modal__content__list" ref={scrollRef}>
      {members.map((item) => (
        <div key={item.id} className="df-row-start  mt-2">
          <div className="df-row-center fs0">
            <CustomRadioSwitch
              checked={membersToReceive?.find((member) => member.id === item.id)}
              small
              isActive={(checked) =>
                !(isLastMember && !checked) && handleToggle(item.id, checked)
              }
            />
            <InitialsBadge
              firstName={item.firstName}
              lastName={item.lastName}
              isCurrentUser={item.id === currentUser?.id}
              addClass="mr-2 ml-2"
            />
            {item.firstName} {item.lastName}
          </div>
          {!!item.OrganizationMember.availableIn?.length && (
            <SelectionRow
              values={item.OrganizationMember?.availableIn.map((item) => ({
                value: item,
                label: item,
                color: "green",
              }))}
              transparent
              bold
              tiny
              nonStretch
              addClassItem="mb-1"
              addClass="ml-2"
            />
          )}
        </div>
      ))}
    </div>
  );

  return (
    <Modal
      titleIcon={<IconUsers addClass="mr-2" />}
      titleText={`${t("RECIPIENT_MEMBERS_MODAL_TITLE")} ${organization.name}?`}
      width="max"
      rounded
      placeBitLow
      noCloseButton
      noCloseOnEsc
      style={{marginTop: "5vh"}}
      onClose={onClose}
    >
      <div className="recipients-tab__members-modal__content">
        <div className="mb-4">{t("RECIPIENT_MEMBERS_MODAL_SUBTITLE_01")}</div>
        <MembersList members={organization.members} />
        <div className="df-row-jce-center mt-2">
          <ButtonTransparent
            name="RECIPIENT_MEMBERS_MODAL_CANCEL"
            color="red"
            small
            onClick={onClose}
            addClass="mr-4"
          />
          <ButtonTransparent
            name="RECIPIENT_MEMBERS_MODAL_OK"
            small
            nonTransparent
            onClick={() => {
              onChoiceNotificationMember(membersToReceive);
              onClose();
            }}
          />
        </div>
        <div className="mb-2 mt-4 __bold">{`${t(
          "RECIPIENT_MEMBERS_MODAL_EMAIL_TITLE_01"
        )} ${organization.name} ${t("RECIPIENT_MEMBERS_MODAL_EMAIL_TITLE_02")}?`}</div>
        <div style={{width: "320px"}}>
          {t("RECIPIENT_MEMBERS_MODAL_EMAIL_DESCRIPTION")}
        </div>
        <div className="df mt-4">
          <IconMailLetter height="30" width="30" color={color.purple} addClass="mr-4" />
          <AddableFormikField
            type="email"
            allowEmpty
            small
            initialValue=""
            loading={emailLoading}
            placeholder="INVITE_SOMEONE_BY_EMAIL_02"
            addPermissions={["ADD_RECIPIENTS_IN_RFT"]}
            add={(value) => {
              onChoiceNotificationMember(membersToReceive);
              onAddEmailRecipients(value);
              onClose();
            }}
          />
        </div>
      </div>
    </Modal>
  );
};
