import React from "react";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
//local
import {Tag} from "components/Tag";
import {LockValue} from "components/LockValue";
import {TimeLeft} from "components/TimeLeft";
import {IconRefresh, IconDotFilled} from "components/icons";
import {toPublicRftId, toRequestToUsId} from "constants/routes.const";
import {color} from "constants/color.consts";
import cn from "classnames";

const PublicRfpsTableItem = ({publicRfp, organizationTurnover, showInfoAndClick}) => {
  const {t} = useTranslation();
  const navigate = useNavigate();

  const availableIn = publicRfp.ProjectItem?.Project?.availableIn;
  const countAvailableIn = availableIn?.length > 1 ? ` +${availableIn?.length - 1}` : "";
  const categories = [
    ...(publicRfp.consultingServices ?? []),
    ...(publicRfp.entrepreneurOffers ?? []),
    ...(publicRfp.entrepreneurWithin ?? []),
    ...(publicRfp.materialSupplierType ?? []),
  ];
  const countCategories = categories?.length > 1 ? ` +${categories?.length - 1}` : "";
  const isHideRfpInfo =
    organizationTurnover && organizationTurnover < publicRfp.minRevenue;

  const iconDotFilled = <IconDotFilled color={color.red} />;

  const handleRedirectOnClick = () => {
    const haveProposal = !!publicRfp.Proposals?.length;
    const requestId = haveProposal ? publicRfp.Proposals[0].id : null;
    const path = haveProposal ? toRequestToUsId(requestId) : toPublicRftId(publicRfp.id);
    navigate(path, {state: haveProposal ? {contractId: null} : null});
  };

  return (
    <div
      className={cn("requests-card_body", {
        __unavailable: isHideRfpInfo,
      })}
      onClick={() => showInfoAndClick && !isHideRfpInfo && handleRedirectOnClick()}
    >
      <div className="requests-card_body__column __wrapper __category">
        {!!categories?.length ? (
          <Tag text={`${t(categories[0])}${countCategories}`} mediumSelectView />
        ) : (
          <Tag
            text={t("PUBLIC_RFPS_PAGE_TABLE_NO_CATEGORY")}
            mediumSelectView
            color={color.midGray}
            noBackground
          />
        )}
      </div>
      <div className="requests-card_body__column __wrapper __region">
        {!!availableIn?.length ? (
          <Tag text={`${t(availableIn[0])}${countAvailableIn}`} mediumSelectView />
        ) : (
          <Tag
            text={t("PUBLIC_RFPS_PAGE_TABLE_NO_REGION")}
            mediumSelectView
            color={color.midGray}
            noBackground
          />
        )}
      </div>
      <div className="requests-card_body__column __wrapper __time-left">
        <TimeLeft date={publicRfp.closedAt} hasShortLabels />
      </div>
      <div className="requests-card_body__column __wrapper __customers">
        <LockValue
          canPermissions={["READ_OTHER_OPEN_RFT"]}
          customLockIcon={iconDotFilled}
          additionalHideCondition={isHideRfpInfo}
        >
          {publicRfp.Buyer?.name ?? "-"}
        </LockValue>
      </div>
      <div className="requests-card_body__column __wrapper __project-name">
        <LockValue
          canPermissions={["READ_OTHER_OPEN_RFT"]}
          customLockIcon={iconDotFilled}
          additionalHideCondition={isHideRfpInfo}
        >
          {publicRfp.ProjectItem?.Project?.name}
        </LockValue>
      </div>
      <div className="requests-card_body__column __wrapper __rfp-name">
        <LockValue
          canPermissions={["READ_OTHER_OPEN_RFT"]}
          customLockIcon={iconDotFilled}
          additionalHideCondition={isHideRfpInfo}
        >
          {publicRfp.name.slice(0, 100) ?? "-"}
        </LockValue>
      </div>
      {showInfoAndClick && (
        <div
          className={cn("requests-card_body__column __wrapper __turnover", {
            __red: isHideRfpInfo,
          })}
        >
          <IconRefresh addClass="mr-1" />
          {`${publicRfp.minRevenue}M`}
        </div>
      )}
    </div>
  );
};

export default PublicRfpsTableItem;
