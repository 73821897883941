// ======================================
// Authorization and user interaction. Data
// ======================================

import {makeAutoObservable} from "mobx";
import i18next from "i18next";
//local
import {TOKEN} from "../constants/auth.const";
import {getStorage} from "../helpers/storage";
import {roles} from "constants/auth.const";
import axiosInstance from "../axios/index";
import {setStorage} from "../helpers/storage";
import {showSuccess, showError} from "../helpers/notifications.helper";
import {formatCompanyNumber} from "../helpers/helper";
import OrganizationsStore from "./organizations.store";
import {noOffice} from "constants/buyer.consts";

const authApiUrl = process.env.REACT_APP_USE_SMS_API === "yes" ? "auth" : "auth-dev";

// eslint-disable-next-line no-console
console.log("Send real SMS:", process.env.REACT_APP_USE_SMS_API);

export class AuthStore {
  // STATE. Observable part
  request_id = null;
  number = null; // *** probably not used
  modalName = null; // name of the specific modal window
  loading = true; // for loading states on auth pages
  btnLoading = false; // for loading states on the buttons
  joinRequest = null; // array of invites from the user
  qrCodeData = null; // data for qrCode picture
  orderRef = null; // number of bankid verification
  signContractSideData = {}; // data with contract signatures on buyer/seller side
  bankIdVerifyData = {}; // data for bankid verification
  organizations = []; // user organization
  token = getStorage(TOKEN); // user token
  counter = 0;
  // user information
  user = {
    email: "",
    firstName: "",
    id: "",
    lastName: "",
    mobilePhone: "",
    language: "en",
    personalNumber: null,
    permissions: [],
  };
  subscribedPermissions = {}; // permissions are adjusted in accordance with the availability in subscriptions

  // STATE. Computed part

  // current organization
  get activeOrganization() {
    return this.user.ActiveOrganization;
  }
  // name of the current organization
  get organizationName() {
    return this.activeOrganization?.name;
  }

  // id of the current organization
  get organizationId() {
    return this.activeOrganization?.id;
  }

  // subscription of the current organization
  get organizationSubscription() {
    return this.activeOrganization?.SubscribeUnit;
  }

  get activeOrganizationId() {
    return this.activeOrganization?.id;
  }

  get hasActiveOrganization() {
    return !!this.activeOrganization;
  }

  get myOrganizations() {
    return this.user.Organizations;
  }

  // getting the authorization status
  get isAuthorized() {
    return !!this.token;
  }

  // getting the client role
  get clientRole() {
    return this.activeOrganization?.role;
  }

  // getting confirmation that the client is just a member of the organization
  get isStaff() {
    return this.clientRole !== roles.MEMBER;
  }

  get hasUserPermissions() {
    return !!this.user.permissions.length;
  }

  constructor() {
    makeAutoObservable(this);
  }

  // ACTIONS
  // ****** REGISTRATION AND AUTHENTICATION ******

  // sending a phone number during registration
  sendNumber(number, token) {
    this.updateBtnLoading(true);
    if (!this.token) {
      return axiosInstance
        .post(
          `${authApiUrl}/send-verify-sms`,
          {number},
          {
            params: {
              token,
            },
          }
        )
        .then(({data}) => {
          this.request_id = data.response.request_id;
          return data;
        })
        .finally(() => this.updateBtnLoading());
    }
  }

  // sending a verification sms code
  checkCode(number, code, request_id) {
    this.updateBtnLoading(true);
    const body = {number, code, request_id};
    if (!this.token) {
      return axiosInstance
        .post(`${authApiUrl}/check-sms-code`, body)
        .then(({data}) => {
          this.setToken(data.token);
          return data;
        })
        .finally(() => this.updateBtnLoading());
    }
  }

  // writing the token to the local storage and to auth-part store
  setToken(value) {
    setStorage(TOKEN, value);
    this.token = value;
  }

  setOrganizations(organizations) {
    this.organizations = organizations;
  }

  // sending the completed registration form
  sendForm(values) {
    this.updateBtnLoading(true);
    const body = {
      ...values,
      companyNumber: formatCompanyNumber(values.companyNumber, true),
    };
    return axiosInstance
      .post(`auth/onboarding`, body)
      .then(({data}) => {
        if (data && data.company) {
          const {
            company: {Organizations, companyNumber},
          } = data;
          setStorage("companyNumber", companyNumber);
          this.setOrganizations(Organizations);
          return data;
        }
      })
      .finally(() => this.updateBtnLoading());
  }

  // checking the token
  checkToken(notLoading) {
    if (this.token) {
      this.loading = !notLoading;
      return axiosInstance
        .get(`user/get-user-data-by-token`)
        .then(({data: {user}}) => {
          this.updateUserData(user);
          this.joinRequest = user.JoinRequest;
          user.ActiveOrganization &&
            OrganizationsStore.getAvailableSubscriptions().then(() => {
              this.subscribedPermissions = OrganizationsStore.subscriptions
                .map((subscription) =>
                  subscription.SubscribeProductPermissions.map((permission) => [
                    permission.PermissionId,
                    subscription.name,
                  ])
                )
                .flat(1)
                .reduce(
                  (permissions, permission) => ({
                    ...permissions,
                    [permission[0]]: permissions[permission[0]]
                      ? [...permissions[permission[0]], permission[1]]
                      : [permission[1]],
                  }),
                  {}
                );
            });
          return user;
        })
        .catch((error) => {
          error.message === "Network Error" && showError(error.message);
        })
        .finally(() => (this.loading = false));
    } else {
      this.loading = false;
      return Promise.reject();
    }
  }

  // sending the joining (inviting) email
  sendJoinEmail(id, joinMessage) {
    return axiosInstance.post(`mailer/send-join-email`, {id, joinMessage}).then(() => {
      this.checkToken();
      this.updateAuthModalName();
    });
  }

  // ****** WORKING WITH THE USER'S ACCOUNT ******

  // getting the current user's data
  getUserData() {
    if (!this.organizationId) return axiosInstance.get(`user/get-user-data`);
  }

  // sending user data for updating
  updateUser(values, noNeedMessage) {
    return axiosInstance.post("user/edit", values).then(({data}) => {
      !noNeedMessage && showSuccess(data?.message);
      return data?.user;
    });
  }

  // recording user data in the auth-part store
  updateUserData(data) {
    this.user = {
      ...this.user,
      ...data,
    };
  }

  // changing the loading status for pages
  updateLoading(active) {
    this.loading = active;
  }

  // changing the loading status for buttons
  updateBtnLoading(active) {
    this.btnLoading = active;
  }

  // setting a name for a modal window (showing a specific model)
  updateAuthModalName(modalName) {
    this.modalName = modalName;
  }

  // setting the delay period between certain actions
  setCounter(value) {
    this.counter = value;
  }

  // sending a confirmation code to an email address
  async sendConfirmationEmail() {
    try {
      return await axiosInstance.post("user/send-confirmation-email");
    } catch (e) {
      throw new Error(e);
    }
  }

  // sending a verification email code for verification purposes
  async checkConfirmationCode(code) {
    try {
      return (await axiosInstance.post("user/check-confirmation-code", {code})).data;
    } catch (e) {
      throw new Error(e);
    }
  }

  // setting up an active organization for a user
  sendSetActiveOrganization(values) {
    return axiosInstance
      .post(`user/set-active-organization`, {
        ...values,
      })
      .then(() => undefined)
      .finally(() => {
        this.checkToken();
      });
  }

  // ****** WORKING WITH BANKID ******

  // getting BankID data for signing
  getBankIdData() {
    axiosInstance.post("auth/bankid").then(({data}) => {
      this.setBankIdData(data);
      this.getBankIdVerify();
    });
  }

  // getting data confirming BankId verification
  getBankIdVerify() {
    return axiosInstance
      .post("auth/bankid/verify", {orderRef: this.orderRef})
      .then(({data}) => {
        this.setBankIdVerifyData(data);
      });
  }

  // recording the data received after BankID in the store on the buyer/seller side
  setSignContractSideData(contractId, side, userId) {
    this.signContractSideData = {
      contractId,
      side,
      userId,
    };
  }

  // getting data confirming BankId verification on Contract signing
  getBankIdDataOnContract() {
    axiosInstance
      .post(
        `rfp/${this.signContractSideData.side}/contract/${this.signContractSideData.contractId}/signatory/${this.signContractSideData.userId}/sign`
      )
      .then(({data}) => {
        this.setBankIdData(data);
        this.getBankIdVerifyOnContract();
      });
  }

  // getting data confirming BankId verification on Contract signing
  getBankIdVerifyOnContract() {
    return axiosInstance
      .post(
        `rfp/${this.signContractSideData.side}/contract/${this.signContractSideData.contractId}/signatory/${this.signContractSideData.userId}/confirm`,
        {
          orderRef: this.orderRef,
        }
      )
      .then(({data}) => this.setBankIdVerifyData(data))
      .catch(() => this.updateAuthModalName(null));
  }

  // getting data cancelling BankId verification on Contract signing
  getBankIdCancelOnContract() {
    return axiosInstance
      .post(
        `rfp/${this.signContractSideData.side}/contract/${this.signContractSideData.contractId}/signatory/${this.signContractSideData.userId}/cancel`,
        {
          orderRef: this.orderRef,
        }
      )
      .catch(() => this.updateAuthModalName(null));
  }

  // recording BankID data in the store
  setBankIdData(data) {
    this.qrCodeData = data.qrCodeData;
    this.orderRef = data.orderRef;
  }

  // recording BankID data after verification in the store
  setBankIdVerifyData(value) {
    this.bankIdVerifyData = value;
    if (this.bankIdVerifyData?.id) {
      this.updateAuthModalName(null);
      showSuccess(i18next.t("VERIFICATION_COMPLETED"));
    }
  }

  // setting new interval for empty insurance data in organization
  async setLastNoInsuranceNotificationDate(payload) {
    try {
      return (
        await axiosInstance.put("user/set-last-no-insurance-notification-date", payload)
      ).data;
    } catch (e) {
      throw new Error(e);
    }
  }

  // setting new interval for empty insurance data in organization
  async setLastUsedDepartmentLabel(lastSelectedDepartmentId) {
    this.user.ActiveOrganization.lastSelectedDepartmentId = lastSelectedDepartmentId;
    if (lastSelectedDepartmentId === noOffice) return;
    return await axiosInstance.put("user/set-last-selected-department-id", {
      lastSelectedDepartmentId,
    });
  }

  setUserLastNoInsuranceNotificationDate(userLastNoInsuranceNotificationDate) {
    this.user = {
      ...this.user,
      ActiveOrganization: {
        ...this.user.ActiveOrganization,
        userLastNoInsuranceNotificationDate,
      },
    };
  }

  // getting public rfp filter settings
  async getPublicRequestsMonitoringSetting(values) {
    return await axiosInstance.get("user/rft-public-monitoring-settings");
  }

  // saving public rfp filter settings
  async setPublicRequestsMonitoringSetting(values) {
    return await axiosInstance.put("user/rft-public-monitoring-settings", values);
  }

  // deleting public rfp filter settings
  async deletePublicRequestsMonitoringSetting() {
    return await axiosInstance.delete("user/rft-public-monitoring-settings");
  }
}

const auth = new AuthStore();
export default auth;
