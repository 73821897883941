import React, {useEffect} from "react";
import {Link, useLocation} from "react-router-dom";
//local
import {
  ORGANIZATIONS,
  INVITE_COMPANIES,
  OUR_FAVOURITES_SUPPLIERS,
  FRAMEWORK_AGREEMENTS,
  OUR_BLACKLIST,
  PROJECTS,
  CONTRACTS,
  REQUESTS_TO_US,
  CONTRACTS_TO_US,
  PUBLIC_RFTS,
  OUR_OFFERS,
  JOINING_ORG,
  OUR_QUALIFICATIONS,
  toOrganizationId,
} from "../constants/routes.const";
import {useTranslation} from "react-i18next";
import {CustomBadge} from "./CustomBadge";
import {
  IconTheater,
  IconSearch,
  IconPlane,
  IconContract,
  IconSkullEmpty,
  IconCheckMark,
  IconBriefcase,
  IconInbox,
  IconOutbox,
  IconRamavtal,
  IconBinoculars,
  IconWallet,
} from "components/icons";
import ButtonCloseDrawer from "./buttons/ButtonCloseDrawer";
import {hasPermission, hasPermissions} from "helpers/helper";
import {LockValue} from "components/LockValue";
import SidebarStar from "assets/images/svg/sidebar-star-no-04-05-subs-v2.svg";
import cn from "classnames";

export const SideBar = ({
  auth,
  organization,
  seller,
  buyer,
  clientStore,
  showSidebar,
}) => {
  const {t} = useTranslation();
  const {activeOrganizationId, token, user} = auth;
  const {unreadRequests, unsignedSellerContracts} = seller;
  const {unsignedBuyerContracts} = buyer;
  const location = useLocation();
  const isDisabled = !token || location.pathname.includes(JOINING_ORG);

  const isShowProjectsContracts = hasPermissions([
    "READ_PROJECTS",
    "READ_BUYER_CONTRACTS",
  ]);
  const isShowOurSuppliers = hasPermission(["READ_OUR_SUPPLIERS"]);

  useEffect(() => {
    !isDisabled && organization.getBlackList();
  }, [organization, isDisabled]);

  // for notifications on seller side
  useEffect(() => {
    !isDisabled && seller.getUnreadRequests();
    !isDisabled && seller.getUnsignedSellerContracts();
  }, [seller, isDisabled]);

  // for notifications on buyer side
  useEffect(() => {
    // token && buyer.getUnreadOffers();
    !isDisabled && buyer.getUnsignedBuyerContracts();
  }, [buyer, isDisabled]);

  const isActiveLink = (link) => location.pathname.includes(link);
  const handleClick = (e, permissions) =>
    token
      ? !!permissions
        ? hasPermission(permissions ?? [])
          ? clientStore.setShowSidebar(false)
          : e.preventDefault()
        : clientStore.setShowSidebar(false)
      : e.preventDefault();

  return (
    <div className={`main-sidebar_wrapper ${showSidebar}`}>
      <ButtonCloseDrawer
        closeDrawer={() => clientStore.setShowSidebar(false)}
        addClass="main-sidebar_btn__close"
        ifOpen={showSidebar === "showSidebar"}
      />
      <div className="main-sidebar_list">
        <h5
          className={cn("main-sidebar_section blue __uppercase", {disabled: isDisabled})}
        >
          {t("PROCUREMENT_SECTION")}
        </h5>
        <LockValue
          canPermissions={["READ_PROJECTS", "READ_BUYER_CONTRACTS"]}
          needAllPermissions
          fullSubcribeButton
          fullSubscribeVerticalPosition
          fullSubcribeButtonClassName="main-sidebar_section-locked"
          addClassDescription={isDisabled ? "light-gray" : ""}
        >
          <Link
            to={PROJECTS}
            end="true"
            disabled={isDisabled}
            className={cn("main-sidebar_btn", {active: isActiveLink(`${PROJECTS}`)})}
            onClick={(e) => handleClick(e, ["READ_PROJECTS"])}
          >
            <IconBriefcase />
            {t("PROJECTS_LINK")}
          </Link>
          <Link
            to={CONTRACTS}
            end="true"
            disabled={isDisabled}
            className={cn("main-sidebar_btn", {active: isActiveLink(`${CONTRACTS}`)})}
            onClick={(e) => handleClick(e, ["READ_BUYER_CONTRACTS"])}
          >
            <IconContract />
            {t("CONTRACTS_LINK")}
            <CustomBadge count={unsignedBuyerContracts} />
          </Link>
        </LockValue>

        {isShowProjectsContracts && !isShowOurSuppliers && (
          <img
            className="main-sidebar_sidebar-star-no-04-05-subs"
            src={SidebarStar}
            alt=""
            width="50%"
            height="50%"
          />
        )}

        <LockValue
          canPermissions={["READ_OUR_SUPPLIERS"]}
          justHide={!isShowProjectsContracts}
          fullSubcribeButton={isShowProjectsContracts}
          fullSubscribeVerticalPosition
          fullSubcribeButtonClassName="main-sidebar_section-locked"
        >
          <Link
            to={OUR_FAVOURITES_SUPPLIERS}
            end="true"
            disabled={isDisabled}
            className={cn("main-sidebar_btn", {
              active: isActiveLink(`${OUR_FAVOURITES_SUPPLIERS}`),
            })}
            onClick={(e) => handleClick(e, ["READ_OUR_SUPPLIERS"])}
          >
            <IconCheckMark />
            {t("OUR_FAVOURITES_LINK")}
          </Link>
        </LockValue>

        <LockValue
          canPermissions={["READ_OUR_BLACKLIST", "READ_ORGANIZATION_RAMAVTAL"]}
          justHide={!(isShowOurSuppliers && isShowProjectsContracts)}
          needAllPermissions
          fullSubcribeButton={isShowOurSuppliers && isShowProjectsContracts}
          fullSubscribeVerticalPosition
          fullSubcribeButtonClassName="main-sidebar_section-locked"
        >
          {!!organization?.blacklistOrganization.length && (
            <Link
              to={OUR_BLACKLIST}
              end="true"
              disabled={isDisabled}
              className={cn("main-sidebar_btn", {
                active: isActiveLink(`${OUR_BLACKLIST}`),
              })}
              onClick={(e) => handleClick(e, ["READ_OUR_BLACKLIST"])}
            >
              <IconSkullEmpty />
              {t("OUR_BLACKLIST_LINK")}
            </Link>
          )}
          <Link
            to={FRAMEWORK_AGREEMENTS}
            end="true"
            disabled={isDisabled}
            className={cn("main-sidebar_btn __wrap", {
              active: isActiveLink(`${FRAMEWORK_AGREEMENTS}`),
            })}
            onClick={(e) => handleClick(e, ["READ_ORGANIZATION_RAMAVTAL"])}
          >
            <IconRamavtal />
            {t("RAMAVTAL_LINK")}
          </Link>
        </LockValue>
        <h5
          className={cn("main-sidebar_section green __uppercase", {disabled: isDisabled})}
        >
          {t("TENDER_SECTION")}
        </h5>
        <LockValue
          canPermissions={["READ_PUBLIC_RFTS"]}
          needLabel
          needShowModalToSubscribe
          dimmedWhenLocked={true}
          noLockIcon
        >
          <Link
            to={PUBLIC_RFTS}
            end="true"
            disabled={isDisabled}
            className={cn("main-sidebar_btn", {active: isActiveLink(PUBLIC_RFTS)})}
            onClick={(e) => handleClick(e, ["READ_PUBLIC_RFTS"])}
          >
            <IconBinoculars />
            {t("PUBLIC_RFP_LINK")}
            {!isActiveLink(PUBLIC_RFTS) && !isDisabled && (
              <span className="live">{t("MAIN_MENU_BADGE_LIVE")}</span>
            )}
          </Link>
        </LockValue>

        {user.hasSellerQualifications && (
          <LockValue
            canPermissions={["READ_QUALIFICATIONS"]}
            needLabel
            needShowModalToSubscribe
            dimmedWhenLocked={true}
            noLockIcon
            badgeCount={user.unreadSellerQualificationsCount}
          >
            <Link
              to={OUR_QUALIFICATIONS}
              end="true"
              className={cn("main-sidebar_btn", {
                active: isActiveLink(OUR_QUALIFICATIONS),
              })}
              onClick={(e) => handleClick(e, ["READ_QUALIFICATIONS"])}
            >
              <IconCheckMark />
              {t("OUR_QUALIFICATIONS_LINK")}
              <CustomBadge count={user.unreadSellerQualificationsCount} />
            </Link>
          </LockValue>
        )}
        <LockValue
          canPermissions={["READ_SELLER_RFTS"]}
          needLabel
          needShowModalToSubscribe
          dimmedWhenLocked={true}
          noLockIcon
        >
          <Link
            to={REQUESTS_TO_US}
            end="true"
            disabled={isDisabled}
            className={cn("main-sidebar_btn", {active: isActiveLink(REQUESTS_TO_US)})}
            onClick={(e) => handleClick(e, ["READ_SELLER_RFTS"])}
          >
            <IconInbox />
            {t("REQUESTS_TO_US_LINK")}
            <CustomBadge count={unreadRequests} />
          </Link>
        </LockValue>

        <LockValue
          canPermissions={["READ_OFFERS"]}
          needLabel
          needShowModalToSubscribe
          dimmedWhenLocked={true}
          noLockIcon
        >
          <Link
            to={OUR_OFFERS}
            end="true"
            disabled={isDisabled}
            className={cn("main-sidebar_btn", {active: isActiveLink(OUR_OFFERS)})}
            onClick={(e) => handleClick(e, ["READ_OFFERS"])}
          >
            <IconOutbox />
            {t("OUR_OFFERS_LINK")}
          </Link>
        </LockValue>

        <LockValue
          canPermissions={["READ_SELLER_CONTRACTS"]}
          needLabel
          needShowModalToSubscribe
          badgeCount={unsignedSellerContracts}
          dimmedWhenLocked={true}
          noLockIcon
        >
          <Link
            to={CONTRACTS_TO_US}
            end="true"
            disabled={isDisabled}
            className={cn("main-sidebar_btn", {
              active: isActiveLink(CONTRACTS_TO_US),
            })}
            onClick={(e) => handleClick(e, ["READ_SELLER_CONTRACTS"])}
          >
            <IconContract />
            {t("CONTRACTS_LINK")}
            <CustomBadge count={unsignedSellerContracts} />
          </Link>
        </LockValue>

        <h5
          className={cn("main-sidebar_section black __uppercase", {disabled: isDisabled})}
        >
          {t("BOTH_SECTION")}
        </h5>
        {/* 
        <Link
          to={FEEDS}
          end="true"
          className="main-sidebar_btn"
          onClick={handleClick}
        >
          <IconBullHorn />
          {t("SAY_SOMETHING")}
        </Link>
        */}
        <Link
          to={ORGANIZATIONS}
          end="true"
          disabled={isDisabled}
          className={cn("main-sidebar_btn", {
            active: location.pathname === ORGANIZATIONS,
          })}
          onClick={handleClick}
        >
          <IconSearch />
          {t("FIND_COMPANIES_LINK")}
        </Link>
        <LockValue
          canPermissions={["READ_INVITE_COMPANIES"]}
          needLabel
          needShowModalToSubscribe
          dimmedWhenLocked={true}
          noLockIcon
        >
          <Link
            to={INVITE_COMPANIES}
            end="true"
            disabled={isDisabled}
            className={cn("main-sidebar_btn", {
              active: isActiveLink(INVITE_COMPANIES),
            })}
            onClick={(e) => handleClick(e, ["READ_INVITE_COMPANIES"])}
          >
            <IconPlane />
            {t("SIDE_INVITE_COMPANIES")}
          </Link>
        </LockValue>
        <Link
          to={toOrganizationId(activeOrganizationId)}
          end="true"
          disabled={isDisabled}
          className={cn("main-sidebar_btn", {
            active: isActiveLink(`${ORGANIZATIONS}/${activeOrganizationId}`),
          })}
          onClick={handleClick}
        >
          <IconTheater />
          {t("OUR_PROFILE_LINK")}
        </Link>
        <Link
          to={`${location.pathname}#your-subscription`}
          end="true"
          disabled={isDisabled}
          className="main-sidebar_btn"
        >
          <IconWallet />
          {t("OUR_SUBSCRIPTIONS_LINK")}
        </Link>
      </div>
    </div>
  );
};
