import React from "react";

export const IconLinkMarkEnergiByggarna = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_17234_64066)">
        <rect width="20" height="20" rx="2" fill="white" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20 0H0V20H20V0ZM2.2 1C1.53726 1 1 1.53726 1 2.2V17.8C1 18.4627 1.53726 19 2.2 19H17.8C18.4627 19 19 18.4627 19 17.8V2.2C19 1.53726 18.4627 1 17.8 1H2.2Z"
          fill="#D2EED9"
        />
        <path
          d="M6.92052 15.2742C6.93349 15.2764 6.9747 15.4209 6.97219 15.4333C6.97721 15.9919 7.56863 16.4835 7.35001 17.0789C7.32156 17.2573 7.17721 17.4123 7.01529 17.4753C6.88958 17.5128 6.75509 17.4989 6.63872 17.4363C6.45483 17.3242 6.33893 17.1584 6.31654 16.9689C6.29332 16.4487 6.59144 16.0024 6.79123 15.5082L6.92114 15.2747L6.92052 15.2742Z"
          fill="#2D9F4A"
        />
        <path
          d="M17.3398 3.29273L16.717 4.87313H16.1268L14.3199 6.43106L15.9634 6.43304V7.3978L12.1977 12.6674H10.6331L9.59143 13.8568C9.28599 14.2066 8.70712 14.5126 8.20817 14.2938L7.32218 15.2529C7.25254 15.329 7.17182 15.3929 7.08306 15.4423C6.76716 15.6159 6.31591 15.235 6.80775 14.7584L7.60084 13.8747C7.49101 13.7108 7.3998 13.5793 7.34624 13.4163L6.32114 14.3896C5.72741 14.9564 5.50524 15.1051 4.93097 14.9035L3.91089 15.8665C3.71005 16.0767 3.25879 15.9628 3.30084 15.5802C3.30687 15.561 3.30873 15.5407 3.30628 15.5207C3.04478 15.3502 2.78704 15.0601 2.70545 14.7833C2.14457 13.48 3.13013 12.7454 2.68181 12.004C2.63997 11.9379 2.59227 11.883 2.55231 11.8211C2.46863 11.7231 2.48055 11.6756 2.62302 11.7423C2.84394 11.8557 3.24436 11.9985 3.45064 12.6529C3.57469 13.0557 3.6498 13.5436 3.50963 13.9264C3.62114 13.9115 3.68411 13.7866 3.72511 13.6731C3.77532 13.5269 3.80461 13.2575 3.76695 13.1247C3.76151 13.1026 3.74164 13.0694 3.80879 13.0883C3.93704 13.1392 4.03599 13.2824 4.07846 13.3998C4.30315 13.9793 3.72637 14.6099 3.60691 15.1341L4.37469 14.422C4.2749 14.174 4.21612 13.8714 4.36256 13.5868C4.48808 13.3857 4.67783 13.2372 4.86005 13.0802L5.34373 12.6722L4.89227 12.6705L4.88662 11.4703L9.16193 8.33656L7.40879 8.3348V7.45903L12.9002 4.66167V4.15066L17.3418 2.5L17.3406 3.29295L17.3398 3.29273Z"
          fill="#2D9F4A"
        />
        <path
          d="M11.7013 8.5022L7.99268 12.2608C7.71088 12.541 7.34415 12.889 7.3726 13.1551L6.32657 14.1791C6.3498 14.0029 6.34205 13.7315 6.23557 13.5537C6.10754 13.2852 5.82678 13.0982 5.53159 13.0504C5.43348 13.0333 5.34331 13.043 5.24373 13.0432L10.5994 8.20507C10.8423 7.96432 11.4686 7.90331 11.7002 8.50198L11.7013 8.5022Z"
          fill="white"
        />
        <path
          d="M5.56989 14.1124L4.04269 15.5613C4.00796 15.3643 3.85922 15.261 3.70796 15.2811L5.27407 13.834C5.47407 13.7899 5.57386 13.932 5.57093 14.1124H5.56989Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.2414 3.2947L16.7578 4.52179L16.758 4.18016L17.2442 2.94073L17.2414 3.2947Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.6096 4.72822H16.1092L14.0473 6.43373L12.8446 6.42822L16.0962 4.26941H16.6067L16.6096 4.72822Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.076 4.60638L13.081 4.2588L13.8006 4.25836L13.076 4.60638Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.8513 6.81897L15.8573 7.33593L12.3015 12.3011L12.3052 11.6881L15.8513 6.81897Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.2136 8.59646L10.4418 11.7396C10.7057 11.8733 10.7126 12.3548 10.4774 12.6387L9.59206 13.6299C9.82344 12.8636 9.24875 12.0661 8.25168 12.3097L11.6383 8.80747C11.9295 8.52465 12.1542 8.18875 12.541 8.19998C12.8728 8.22994 13.0862 8.35174 13.2136 8.59646Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.3224 7.5498L9.45587 8.18351L7.59081 8.17536L7.59478 7.55575L10.3224 7.5498Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.0944 12.437L10.7665 12.4401C10.8881 12.1198 10.8057 11.8637 10.6751 11.7463L12.0895 11.7476L12.0944 12.437Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.83536 13.3797L7.26402 15.1716C7.25105 14.989 7.15335 14.8317 6.91862 14.8606L8.49791 13.1022C8.64561 12.9701 8.9113 13.189 8.83536 13.3797Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.58181 12.4311L5.10733 12.4359L5.11089 11.7456L6.41863 11.7476L5.58181 12.4311Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_17234_64066">
          <rect width="20" height="20" rx="2" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
