import React from "react";
import {useNavigate} from "react-router-dom";
//local
import {IconDocumentPen, IconCog, IconBubblesMessage} from "components/icons";
import {ProjectLabel} from "components/ProjectLabel";
import {ButtonTransparent} from "components/buttons/ButtonTransparent";
import {CustomBadge} from "components/CustomBadge";
import {toProjectId} from "constants/routes.const";
import {color} from "constants/color.consts";
import {LockValue} from "components/LockValue";
import {hasPermission} from "helpers/helper";
import cn from "classnames";

const ProjectItem = ({project, ownUser, editProject}) => {
  const navigate = useNavigate();

  const canEdit = hasPermission(["EDIT_PROJECT"]);
  const isDocuments = !!+project.offersAmount || !!+project.unreadOffersAmount;
  const isMessages = !!+project.messagesAmount || !!+project.unreadMessagesAmount;

  return (
    <div
      className="projects-table_item"
      onClick={() => navigate(toProjectId(project.id))}
    >
      <div className="projects-table_item_row">
        <ProjectLabel
          name={project.name}
          code={project.reference}
          isCreating={false}
          color={!ownUser && "discreteText"}
          width="300"
        />
        {isMessages && (
          <div className="projects-table_item__indicator __wrapper">
            <IconBubblesMessage addClass={`icon-table mr-2`} color={color.black} />
            <CustomBadge
              count={project.messagesAmount}
              color="midGray"
              addClass="mr-1 ml-0"
            />
            <CustomBadge count={project.unreadMessagesAmount} color="red" />
          </div>
        )}
        {isDocuments && (
          <div
            className={cn("projects-table_item__indicator __wrapper pl-4", {
              "__left-border": !!isMessages,
            })}
          >
            <IconDocumentPen addClass={`icon-table mr-2`} color={color.black} />
            <CustomBadge
              count={project.offersAmount}
              color="midGray"
              addClass="mr-1 ml-0"
            />
            <CustomBadge count={project.unreadOffersAmount} color="red" />
          </div>
        )}
        <LockValue canPermissions={["EDIT_PROJECT"]} justHide>
          <ButtonTransparent
            icon={<IconCog />}
            noBorder
            tiny
            addClass="ml-6 pr-0"
            onClick={(e) => {
              canEdit && e.stopPropagation();
              canEdit && editProject(project.id);
            }}
          />
        </LockValue>
      </div>
    </div>
  );
};

export default ProjectItem;
