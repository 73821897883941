import React, {useState, useEffect, useMemo, useRef} from "react";
import {inject, observer} from "mobx-react";
import {Select as AntdSelect} from "antd";
import {useTranslation} from "react-i18next";
import i18next from "i18next";
//local
import Select from "components/selects/Select";
import {InfoTooltip} from "components/InfoTooltip";
import {LockValue} from "components/LockValue";
import {ButtonTransparent} from "components/buttons/ButtonTransparent";
import {color} from "constants/color.consts";
import {dateFormat} from "helpers/datetime";
import {showSuccess} from "helpers/notifications.helper";
import {
  IconChevronDownMini,
  IconRefresh,
  IconCamera,
  IconDownload,
} from "components/icons";
import {hasPermission, hasPermissions, openNewTab} from "helpers/helper";
import cn from "classnames";

const {Option} = AntdSelect;

const FinancialSnapshotSelect = inject("store")(
  observer(
    ({
      store: {auth, organization},
      companyNumber,
      initialSnapshots,
      initialOpen,
      updateUsedCredits,
      addClass,
      addCustomDropdownClass,
      horizontalMode,
    }) => {
      const {
        user: {ActiveOrganization},
      } = auth;
      const language = i18next.language;
      const dateOptions = {
        year: "2-digit",
        month: "2-digit",
        day: "2-digit",
      };
      const {t} = useTranslation();

      const snapshotButtonRef = useRef();
      const [isOpen, setIsOpen] = useState(initialOpen);

      const [snapshots, setSnapshots] = useState(initialSnapshots ?? []);
      const [loading, setLoading] = useState(false);
      const [innerButtonLoading, setInnerButtonLoading] = useState(false);

      const hasSnapshots = useMemo(() => !!snapshots?.length, [snapshots]);
      const iconColor = useMemo(
        () => (hasSnapshots ? color.white : color.blue),
        [hasSnapshots]
      );

      const currentYear = new Date().getFullYear();
      const nextMonth = new Date().getMonth() + 1;
      const newMonthStartDate = new Date(currentYear, nextMonth, 1).toLocaleString(
        language,
        dateOptions
      );
      const monthlyCredits = useMemo(
        () => ActiveOrganization.creditsUpdateLimit,
        [ActiveOrganization]
      );
      const [usedCredits, setUsedCredits] = useState(
        monthlyCredits - ActiveOrganization.creditsUpdateUsed
      );

      const canCreateSnapshots = useMemo(() => usedCredits > 0, [usedCredits]);
      const needGetSnapshots = useMemo(
        () =>
          hasPermission(["ENABLE_COMPANY_CREDITSAFE_SNAPSHOTS"]) &&
          !!companyNumber &&
          !initialSnapshots,
        [companyNumber, initialSnapshots]
      );

      const options = snapshots?.map((item, i) => (
        <Option key={item.id} value={item.id}>
          <button
            key={item.File?.url}
            className="financial-snapshot-select-dropdown-download-button"
            onClick={() => openNewTab(item.File?.url)}
          >
            <IconDownload height="16" width="16" />
            <span>{`${dateFormat(item.File.createdAt)}.pdf`}</span>
          </button>
        </Option>
      ));

      const createSnapshotButton = (
        <LockValue
          canPermissions={["CREATE_RFT_OFFERS_OFFER_SNAPSHOT"]}
          needAllPermissions
          needLabel
          addClassIcon={horizontalMode ? "" : "ml-0 mr-2"}
          needShowModalToSubscribe
        >
          <ButtonTransparent
            name="FINANCIAL_SNAPSHOT_SELECT_CREATE_BUTTON"
            icon={<IconRefresh height="16" width="16" color={color.white} />}
            nonTransparent={canCreateSnapshots}
            small
            fullWidth
            onClick={() =>
              hasPermission(["CREATE_RFT_OFFERS_OFFER_SNAPSHOT"]) && addSnapshot(true)
            }
            disabledTooltip="FINANCIAL_SNAPSHOT_SELECT_CREATE_BUTTON_TOOLTIP"
            disabled={!canCreateSnapshots}
            addClass={horizontalMode ? "ml-0 mr-0 full-width" : ""}
            loading={innerButtonLoading}
          />
        </LockValue>
      );

      const updatesInfo = (
        <React.Fragment>
          <div className="fz-10 mid-gray mt-2 ws-nowrap">
            {t("FINANCIAL_SNAPSHOT_SELECT_CREDIT_UPDATE_USED")}
            <span className="ml-1 black">{usedCredits >= 0 ? usedCredits : 0}</span>
          </div>
          <div className="fz-10 mid-gray ws-nowrap">
            {t("FINANCIAL_SNAPSHOT_SELECT_CREDIT_UPDATE_NEW_DATE")?.format(
              monthlyCredits
            )}
            <span className="ml-1 black">{newMonthStartDate}</span>
          </div>
        </React.Fragment>
      );

      const openSelect = () =>
        hasSnapshots
          ? setIsOpen((prevState) => !prevState)
          : hasPermissions([
              "ENABLE_COMPANY_CREDITSAFE_SNAPSHOTS",
              "CREATE_RFT_OFFERS_OFFER_SNAPSHOT",
            ]) && addSnapshot();

      const backdropListener = (e) => {
        if (snapshotButtonRef.current && isOpen) {
          const select = document.querySelector(
            ".financial-snapshot-select-dropdown.ant-select-dropdown"
          );
          select && !select.contains(e.target) && setIsOpen(false);
        }
      };

      const addSnapshot = async (isInnerLoading) => {
        try {
          isInnerLoading ? setInnerButtonLoading(true) : setLoading(true);
          const snapshot = await organization.createOrganizationSnapshot(companyNumber);
          const currentUsedCredits = monthlyCredits - snapshot.creditsUpdateUsed;
          setUsedCredits(currentUsedCredits);
          updateUsedCredits && updateUsedCredits(currentUsedCredits);
          showSuccess(t("FINANCIAL_SNAPSHOT_SUCCESS_CREATING"));
          setSnapshots((prevState) => [snapshot, ...prevState]);
        } finally {
          setLoading(false);
          setInnerButtonLoading(false);
        }
      };

      useEffect(() => {
        needGetSnapshots &&
          organization.getOrganizationSnapshots(companyNumber).then(setSnapshots);
      }, [needGetSnapshots, organization, companyNumber]);

      useEffect(() => {
        document.body.addEventListener("click", backdropListener);
        return () => {
          document.body.removeEventListener("click", backdropListener);
        };
      });

      useEffect(() => {
        setSnapshots(initialSnapshots);
      }, [initialSnapshots]);

      return (
        <div
          className={cn("financial-snapshot-select", {[addClass]: !!addClass})}
          onClick={(e) => e.stopPropagation()}
        >
          <LockValue
            canPermissions={["ENABLE_COMPANY_CREDITSAFE_SNAPSHOTS"]}
            needAllPermissions
            needLabel
            needShowModalToSubscribe
          >
            <LockValue
              canPermissions={["CREATE_RFT_OFFERS_OFFER_SNAPSHOT"]}
              additionalShowCondition={
                (!companyNumber &&
                  hasPermission(["ENABLE_COMPANY_CREDITSAFE_SNAPSHOTS"])) ||
                hasSnapshots ||
                !hasPermission(["ENABLE_COMPANY_CREDITSAFE_SNAPSHOTS"])
              }
              needAllPermissions
              needLabel
              needShowModalToSubscribe
            >
              <ButtonTransparent
                ref={snapshotButtonRef}
                name={
                  hasSnapshots
                    ? dateFormat(snapshots[0].File.createdAt)
                    : "FINANCIAL_SNAPSHOT_SELECT_BUTTON"
                }
                onClick={openSelect}
                small
                nowrap
                icon={<IconCamera color={iconColor} />}
                iconRight={<IconChevronDownMini addClass="mr-0 ml-2" color={iconColor} />}
                nonTransparent={hasSnapshots}
                disabled={innerButtonLoading || !companyNumber}
                loading={loading}
              />
            </LockValue>

            <Select
              open={isOpen}
              placement="bottomRight"
              onChange={() => setIsOpen(false)}
              addDropdownClass={cn("financial-snapshot-select-dropdown", {
                [addCustomDropdownClass]: !!addCustomDropdownClass,
                "__horizontal-view": !!horizontalMode,
              })}
              virtual={!horizontalMode}
              needRenderDropdownIntoSelect
              customBottomRow={
                horizontalMode ? (
                  <div className="pl-2 pr-2">
                    {createSnapshotButton}
                    {updatesInfo}
                  </div>
                ) : (
                  <React.Fragment>
                    {createSnapshotButton}
                    <div className="ml-2 mr-2 mb-1">{updatesInfo}</div>
                  </React.Fragment>
                )
              }
            >
              {options}
            </Select>
          </LockValue>
          {!hasSnapshots && (
            <InfoTooltip
              tooltip={
                companyNumber
                  ? "FINANCIAL_SNAPSHOT_TOOLTIP"
                  : "FINANCIAL_SNAPSHOT_WO_COMPANY_NUMBERTOOLTIP"
              }
              centerText
              addClass="ml-1"
            />
          )}
        </div>
      );
    }
  )
);

export default FinancialSnapshotSelect;
