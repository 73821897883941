import React from "react";
import {useTranslation} from "react-i18next";

const EmptyData = ({addClass, text}) => {
  const {t} = useTranslation();

  return <h4 className={addClass}>{t(text || "NO_ANY_DATA")}</h4>;
};

export default EmptyData;
