import React, {useState} from "react";
import {useTranslation} from "react-i18next";
//local
import HandyPointerBlob from "components/HandyPointerBlob";
import {Tag} from "components/Tag";
import FinancialSnapshotSelect from "components/selects/FinancialSnapshotSelect";

import cn from "classnames";

const CreditReportCompanyWidget = ({
  companyNumber,
  snapshots,
  ownOrganization,
  addClass,
}) => {
  const {t} = useTranslation();

  const [usedCredits, setUsedCredits] = useState(
    ownOrganization.creditsUpdateLimit - ownOrganization.creditsUpdateUsed
  );

  return (
    <div className={cn("credit-report-company-widget", {[addClass]: !!addClass})}>
      <div className="df-row-center">
        <h2 className="credit-report-company-widget__title">
          {t("COMPANY_CREDITSAFE_WIDGET_TITLE")}
        </h2>
        <span className="credit-report-company-widget__title-number">
          {snapshots.length}
        </span>
        <Tag text={companyNumber} uppercase addClass="fz-14" />
        <HandyPointerBlob
          showNewsBadge
          addClass="ml-2"
          text="NEW_FEATURE_COMPANY_CREDITSAFE_WIDGET"
        />
      </div>
      <div className="mt-4">
        <div className="fz-16">
          {t("COMPANY_CREDITSAFE_WIDGET_GET_SNAPSHOT_QUESTION")?.format(companyNumber)}
        </div>
        <div className="fz-12 mt-2">
          {t("COMPANY_CREDITSAFE_WIDGET_UPDATE_STATISTIC")?.format(1, usedCredits)}
        </div>
      </div>
      <FinancialSnapshotSelect
        companyNumber={companyNumber}
        horizontalMode
        initialOpen
        initialSnapshots={snapshots}
        updateUsedCredits={setUsedCredits}
        addClass="mt-8"
      />
    </div>
  );
};

export default CreditReportCompanyWidget;
