import React from "react";
import {ErrorMessage, Field} from "formik";
import {useTranslation} from "react-i18next";
import {Form, Formik} from "formik";
import {Input} from "formik-antd";
import {Button} from "antd";
import * as yup from "yup";
import {stringRequired, emailRequired} from "yup/defaultValudations";
//local
import {checkField, hasPermission} from "helpers/helper";
import {IconPlusGray, IconPlus} from "components/icons";
import {Preloader} from "components/Preloader";
import {color} from "constants/color.consts";
import cn from "classnames";

// iconColor: "blue" | "red" | "green" | "purple" | "orange"
export const AddableFormikField = ({
  initialValue,
  placeholder,
  type,
  add,
  small,
  loading,
  addClass,
  disabled,
  iconColor,
  addPermissions = [],
  allowEmpty,
}) => {
  const {t} = useTranslation();

  const CustomField = ({field, form, ...props}) => (
    <Input
      {...field}
      {...props}
      className={`addable-field_input ${small ? "__small" : ""}`}
      suffix={
        <Button
          htmlType="submit"
          disabled={!field.value}
          shape="circle"
          className={`addable-field_button ${small ? "__small" : ""}`}
          icon={
            loading ? (
              <Preloader size="default" addClass="mt-0" />
            ) : field.value ? (
              <IconPlus color={color[iconColor]} />
            ) : (
              <IconPlusGray />
            )
          }
        />
      }
    />
  );

  const types = {
    string: stringRequired(),
    email: emailRequired(),
  };

  const schema = yup.object().shape({
    field: types[type] ? types[type] : stringRequired(),
  });

  const schemaEmpty = yup.object().shape({
    field: type === "email" ? yup.string().email() : yup.string(),
  });

  const onSubmit = (values, {resetForm}) => {
    if (!hasPermission(addPermissions)) return;
    add(values);
    resetForm();
  };

  return (
    <div className={cn("addable-field_wrapper", {[addClass]: !!addClass})}>
      <Formik
        initialValues={{field: initialValue}}
        validationSchema={allowEmpty ? schemaEmpty : schema}
        onSubmit={onSubmit}
      >
        <Form>
          <div className="field">
            <Field
              name="field"
              placeholder={t(placeholder)}
              className="addable-field_field"
              disabled={disabled}
              component={CustomField}
            />
            <ErrorMessage name="field">
              {(msg) => <p className="form-error">{checkField(msg)}</p>}
            </ErrorMessage>
          </div>
        </Form>
      </Formik>
    </div>
  );
};
