import React, {memo} from "react";

export const IconHandOnLeft = memo(({color, height, width, addClass}) => {
  return (
    <svg
      width={width || "20"}
      height={height || "20"}
      viewBox="0 0 20 20"
      fill="none"
      className={addClass}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 17C8 16.588 8.125 16.206 8.339 15.887C7.56 15.613 7 14.871 7 14C7 13.588 7.125 13.206 7.339 12.887C6.56 12.613 6 11.871 6 11C6 10.636 6.098 10.294 6.269 9.99999H3C1.897 9.99999 1 9.10299 1 7.99999C1 6.89699 1.897 5.99999 3 5.99999H10.373L8.04 4.65399C7.565 4.39299 7.227 3.96699 7.087 3.45499C6.951 2.95599 7.023 2.43699 7.289 1.99299C7.833 1.08599 9.063 0.739992 10.031 1.22299C10.092 1.25299 11.399 1.89099 15.381 3.88299C17.256 4.82099 18.597 5.84999 19.365 6.94199C19.968 7.79899 19.999 8.39099 19.999 8.50099V13.501C19.999 14.349 19.754 15.133 19.269 15.832C18.801 16.508 18.117 17.091 17.236 17.566C15.493 18.505 12.99 19.001 9.999 19.001C8.896 19.001 7.999 18.104 7.999 17.001L8 17ZM16.763 16.685C17.784 16.136 19 15.137 19 13.5V8.50999C18.997 8.47099 18.95 8.04999 18.475 7.41499C18.001 6.78199 17.003 5.80999 14.935 4.77699C10.881 2.74999 9.604 2.12699 9.592 2.12099C9.59 2.11999 9.589 2.11999 9.588 2.11899C9.085 1.86799 8.426 2.04599 8.149 2.50799C8.024 2.71599 7.991 2.95899 8.054 3.19299C8.122 3.44199 8.29 3.65099 8.526 3.77999C8.529 3.78199 8.533 3.78399 8.536 3.78599L11.241 5.34699C11.824 5.66599 12.069 6.02499 11.97 6.41399C11.871 6.80399 11.481 7.00099 10.813 7.00099H3.001C2.45 7.00099 2.001 7.44999 2.001 8.00099C2.001 8.55199 2.45 9.00099 3.001 9.00099H9.501C9.777 9.00099 10.001 9.22499 10.001 9.50099C10.001 9.77699 9.777 10.001 9.501 10.001H8.001C7.45 10.001 7.001 10.45 7.001 11.001C7.001 11.552 7.45 12.001 8.001 12.001H9.501C9.777 12.001 10.001 12.225 10.001 12.501C10.001 12.777 9.777 13.001 9.501 13.001H9.001C8.45 13.001 8.001 13.45 8.001 14.001C8.001 14.552 8.45 15.001 9.001 15.001H10.501C10.777 15.001 11.001 15.225 11.001 15.501C11.001 15.777 10.777 16.001 10.501 16.001H10.001C9.45 16.001 9.001 16.45 9.001 17.001C9.001 17.552 9.45 18.001 10.001 18.001C12.827 18.001 15.166 17.546 16.764 16.686L16.763 16.685Z"
        fill={color || "#000"}
      />
    </svg>
  );
});

export default IconHandOnLeft;
