import React, {useMemo} from "react";
import {useTranslation} from "react-i18next";
//local
import SellerSideProposalDescription from "./SellerSideProposalDescription";
import LoadDocsColumn from "components/LoadDocsColumn";
import HistoryInfo from "components/HistoryInfo";
import {historyOfferAmountTableColumns} from "constants/table.consts";
import {ButtonTransparent} from "components/buttons/ButtonTransparent";
import {IconEdit, IconDocumentPen, IconPrint} from "components/icons";
import {dateFormat, dateTimeFormat} from "helpers/datetime";
import {hasPermission} from "helpers/helper";
import {LockValue} from "components/LockValue";
import {InfoTooltip} from "components/InfoTooltip";

const SellerSideProposalView = ({offer, request, language, currentUser, onEdit}) => {
  const {t} = useTranslation();

  const organizationName = useMemo(() => offer?.Proposal?.Organization?.name, [offer]);

  const handlePrint = () => {
    const originalTitle = document.title;
    const projectName = request?.Project?.name;
    const rftName = request?.name;
    const anbud = t("ANBUD");
    document.title = `${anbud}・${projectName}・${rftName}・${organizationName}.pdf`;
    window.print();
    document.title = originalTitle;
  };

  return (
    <React.Fragment>
      <div className="df-row">
        <div className="main-info">
          <div className="main-info_block">
            <div className="main-info_block_header">{t("OFFER_DATE")}</div>
            <div className="main-info_block_date">{dateTimeFormat(offer?.createdAt)}</div>
          </div>
          <div className="main-info_block">
            <div className="main-info_block_header">{t("VALID_TILL")}</div>
            <div className="main-info_block_date">{dateFormat(offer?.validUntil)}</div>
          </div>
          <div className="pt-8">
            <LockValue
              canPermissions={["EDIT_RFT_OFFERS_OFFER"]}
              needLabel
              needShowModalToSubscribe
              noLockIcon
              dimmedWhenLocked
            >
              <ButtonTransparent
                addClass="view-header__status-row__button button-edit"
                type="link"
                icon={<IconEdit />}
                onClick={() => hasPermission(["EDIT_RFT_OFFERS_OFFER"]) && onEdit()}
                tooltip="EDIT_OFFER_SELLER_SIDE_TOOLTIP"
              />
            </LockValue>
          </div>
        </div>
        <div className="print-button df-row-jce-center mt-8">
          <InfoTooltip addClass="df mr-2" tooltip="PRINT_OFFER_BUTTON_INFO_TOOLTIP" />
          <ButtonTransparent
            addClass="__button"
            name={t("PRINT_OR_SAVE_AS_PDF")}
            small
            icon={<IconPrint />}
            onClick={handlePrint}
          />
        </div>
      </div>
      <div className="main-info">
        <div className="main-info_block">
          <div className="main-info_block_header">{t("OFFER_FROM")}</div>
          <div className="main-info_block_name">{organizationName}</div>
        </div>
        <div className="main-info_block">
          <div className="main-info_block_header">{t("TOTAL_OFFER")}</div>
          <div className="main-info_block_value df-row-baseline">
            <span className="seller-value green">
              {offer?.price?.toLocaleString(language ?? "sv")}
            </span>
            <span className="currency green">{` ${request.CurrencyCode}`}</span>
            <HistoryInfo
              data={offer?.OfferPriceHistoryEvents}
              columns={historyOfferAmountTableColumns}
              showAmount
            />
          </div>
        </div>
        <div className="ml-8"></div>
      </div>

      <SellerSideProposalDescription offer={offer} />
      <LoadDocsColumn
        title="DOCUMENTS"
        titleIcon={<IconDocumentPen />}
        addClass="mt-8"
        needZipButton
        titleLeft
        fullWidth
        needAuthorInfo
        currentUser={currentUser}
        docs={offer?.OfferFiles ?? []}
      />
    </React.Fragment>
  );
};

export default SellerSideProposalView;
